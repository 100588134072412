// https://developer.okta.com/code/react/okta_react_sign-in_widget/

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import OktaSignIn from '@okta/okta-signin-widget';


// import OktaSignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in-no-jquery';

import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import debug from '../../services/debug';
import getContent from '../../services/content/getContent';
import logo from '../../assets/images/logo-brand.svg';

class OktaSignInWidget extends Component {
    componentDidMount() {
        this.removeWidget();
        this.createWidget();
        this.renderWidget();
    }

    componentDidUpdate() {
        // this.removeWidget();
        // this.createWidget();
        // this.renderWidget();
    }

    componentWillUnmount() {
        this.removeWidget();
    }


    createWidget() {
        const {
            baseUrl,
            issuer,
            redirectUri,
        } = this.props;

        this.widget = new OktaSignIn({
            logo,
            language: 'en',
            i18n: {
                en: {
                    'primaryauth.title': getContent(['title']),
                },
            },
            brandName: getContent(['title']),
            redirectUri,
            baseUrl,
            authParams: {
                issuer,
                // pkce: true,
                pkce: false, // IE11 does not support pkce https://github.com/okta/okta-oidc-js/issues/591
            },
        });
    }

    removeWidget() {
        if (this.widget) {
            try {
                this.widget.remove();
            } catch (err) {
                debug('widget remove', err.message);
            }
        }
    }

    renderWidget() {
        const {
            onSuccess,
            onError,
        } = this.props;

        // eslint-disable-next-line react/no-find-dom-node
        const el = ReactDOM.findDOMNode(this);
        this.widget.renderEl({ el }, onSuccess, onError);
    }

    render() {
        return <div />;
    }
}

OktaSignInWidget.propTypes = {
    redirectUri: PropTypes.string,
    issuer: PropTypes.string,
    baseUrl: PropTypes.string,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
};

OktaSignInWidget.defaultProps = {
    redirectUri: '',
    issuer: '',
    baseUrl: '/',
    onSuccess: () => {},
    onError: () => {},
};

export default OktaSignInWidget;
