import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

import {
    Container,
    Row,
    Col,
    H4,
    P,
} from '@bootstrap-styled/v4';

import {
    NotificationBarFragment,
} from '../../theme/components';
import { getEnvironmentVariable } from '../../services/environment/environment-service';

const NotificationBar = () => {
    const getEnvData = () => {
        const noticationENV = {
            PRELOGON_BANNER_ENABLED: getEnvironmentVariable('REACT_APP_PRELOGON_BANNER_ENABLED') || null,
            PRELOGON_BANNER_TITLE: getEnvironmentVariable('REACT_APP_PRELOGON_BANNER_TITLE') || '',
            PRELOGON_BANNER_TEXT: getEnvironmentVariable('REACT_APP_PRELOGON_BANNER_TEXT') || '',
            PRELOGON_BANNER_BORDER_COLOUR: getEnvironmentVariable('REACT_APP_PRELOGON_BANNER_BORDER_COLOUR') || '#ffffff',
            PRELOGON_BANNER_BACKGROUND_COLOUR: getEnvironmentVariable('REACT_APP_PRELOGON_BANNER_BACKGROUND_COLOUR') || '#ffffff',
            PRELOGON_BANNER_FONT_COLOUR: getEnvironmentVariable('REACT_APP_PRELOGON_BANNER_FONT_COLOUR') || '#000000',
        };
        return noticationENV;
    };

    const [visible, setVisible] = useState(false);
    const [noticationENV, setNoticationENV] = useState({});
    useEffect(() => {
        const envData = getEnvData();
        if (envData.PRELOGON_BANNER_ENABLED === 'true') {
            setVisible(true);
        }
        setNoticationENV(envData);
    }, []);

    return !visible ? (<span />) : (
        <NotificationBarFragment
            borderColour={noticationENV.PRELOGON_BANNER_BORDER_COLOUR}
            backgroundColour={noticationENV.PRELOGON_BANNER_BACKGROUND_COLOUR}
        >
            <Container>
                <Row>
                    <Col>
                        <H4>{ReactHtmlParser(noticationENV.PRELOGON_BANNER_TITLE)}</H4>
                        <P style={{ color: noticationENV.PRELOGON_BANNER_FONT_COLOUR }}>{ReactHtmlParser(noticationENV.PRELOGON_BANNER_TEXT)}</P>
                    </Col>
                </Row>
            </Container>
        </NotificationBarFragment>
    );
};


export default NotificationBar;
