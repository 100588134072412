import styled, { withTheme } from 'styled-components';
import bp from '@bootstrap-styled/css-mixins/lib/breakpoints';

const ContactOuterFragment = styled.div`
    margin:30px 0 140px 0;
    position:relative;
    .card.card-contact {
        margin:0 0 15px 0;
        box-shadow: 0 0 10px 0 rgba(200, 200, 200,1);
        ${props => bp.up('lg', props.theme['$grid-breakpoints'], `
            box-shadow:none;
            position:absolute;
            left:0;
            top:80px;
            width:700px;
            z-index:100;
        `)}
    }

`;

export default withTheme(ContactOuterFragment);
