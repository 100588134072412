import React, { Component } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';

import debug from '../../services/debug';
import { getEnvironmentVariable } from '../../services/environment/environment-service';

if (`${process.env.NODE_ENV}` !== 'test') {
    debug('Google analytics being tracked', getEnvironmentVariable('REACT_APP_GA_ACCOUNT'));
    ReactGA.initialize(getEnvironmentVariable('REACT_APP_GA_ACCOUNT'));
}

const withTracker = (WrappedComponent, options = {}) => {
    const trackPage = (page) => {
        ReactGA.set({
            page,
            ...options,
        });
        ReactGA.pageview(page);
    };

    const HOC = class extends Component {
        componentDidMount() {
            const { location } = this.props;
            const page = location.pathname;
            trackPage(page);
        }

        componentDidUpdate(nextProps) {
            const { location } = this.props;
            const currentPage = location.pathname;
            const nextPage = nextProps.location.pathname;

            if (currentPage !== nextPage) {
                // Scroll to the top of the new page
                window.scrollTo(0, 0);

                // Track page in Google Analytics
                trackPage(nextPage);
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };

    HOC.propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        location: PropTypes.object,
    };

    HOC.defaultProps = {
        location: {},
    };

    return HOC;
};


export default withTracker;
