import styled, { withTheme } from 'styled-components';

const AccountFormFragment = styled.div`
    form .form-group {
        padding:0 0 15px 0;
        & > label {
            display:block;
            margin:0 0 5px 0;
            color: ${props => props.theme.$gray};
        }
        & > .form-control {
            &:disabled {
                border:1px solid ${props => props.theme['$gray-light-alt']};
                color: ${props => props.theme['$gray-light']};
                background-color: ${props => props.theme.$contrast};
                cursor: not-allowed! important;
            }
            width:100%;
            padding:10px 12px;
            border-radius:0;
            display:block;
            box-shadow:none;
            background-color: ${props => props.theme.$white};
            border:1px solid ${props => props.theme.$green};
            font-size: ${props => props.theme['$font-size-16']};
            ${props => props.theme.mixinPlaceHolder(`
                color:${props.theme['$gray-muted']};
                font-style: italic;
                text-transform: uppercase;
            `)}
        }
        & > button {
            margin:5px 0 0 0;
            background-color: ${props => props.theme.$green};
            color: ${props => props.theme.$white};
            &:hover {
                background-color: ${props => props.theme.$green};
                color: ${props => props.theme.$white};
                opacity:0.6;
    
            }
        }
    }
`;

export default withTheme(AccountFormFragment);
