import * as Immutable from 'immutable';

import {
    SESSION_INACTIVITY_TIMER_RESET,
    SESSION_INACTIVITY_TIMER_STARTED,
    SESSION_INACTIVITY_TIMEOUT_LIMIT_REACHED,
    SESSION_EXPIRY_LIMIT_REACHED,
    SESSION_GET_ID,
    SESSION_SET_ID,
    SESSION_RESET,
    SESSION_RESET_ID,
} from '../../actions/session/types';

/**
*
* Set the initial state of our app
*
*/
const schema = {
    inactivityTimerReset: true,
    automaticTimeoutLimitReached: false,
    automaticExpiryLimitReached: false,
    id: null,
}

const initialState = Immutable.Map(schema);

/**
*
* The Session Reducer
*
* A reducer responsible for managing details of a single user session
*
*/
const sessionReducer = (state = initialState, action) => {
    switch (action.type) {

    case SESSION_RESET:

        return initialState;

    case SESSION_RESET_ID:

        return state.set('id', null);

    case SESSION_EXPIRY_LIMIT_REACHED:

        return state.set('automaticExpiryLimitReached', true).set('inactivityTimerReset', true);

    case SESSION_INACTIVITY_TIMEOUT_LIMIT_REACHED:

        return state.set('automaticTimeoutLimitReached', true).set('inactivityTimerReset', true);

    case SESSION_INACTIVITY_TIMER_STARTED:

        if (state.get('inactivityTimerReset') === true) {
            return state.set('inactivityTimerReset', false);
        }
        return state;

    case SESSION_INACTIVITY_TIMER_RESET:

        return state.set('inactivityTimerReset', true);

    case SESSION_GET_ID:

        return state.set('id', 'LOADING');

    case SESSION_SET_ID:

        return state.set('id', action.payload);

    default:

        return state;
    }
};

export { initialState };

export default sessionReducer;
