import styled, { withTheme } from 'styled-components';
import bp from '@bootstrap-styled/css-mixins/lib/breakpoints';

const PortfolioGraphFragment = styled.div`
    ${props => bp.down('md', props.theme['$grid-breakpoints'], `
        padding:15px 0 0 0;
    `)}
`;

export default withTheme(PortfolioGraphFragment);
