import styled, { withTheme } from 'styled-components';
import BaseDataCard from './base-data-card';

const DashboardContactCard = styled(BaseDataCard)`
    
    & > .card-header {
        padding-top:9px;
        padding-bottom:9px;
        h6 {
            text-transform: uppercase;
            font-size: ${props => props.theme['$font-size-16']};
        }
    }
    & > .card-block {
        padding:30px;
        h3 {
            font-size: ${props => props.theme['$font-size-40']};
        }
        h4 {
            font-size: ${props => props.theme['$font-size-14']};
            color: ${props => props.theme.$green};
            text-transform: uppercase;
        }
        h5 {
            font-size: ${props => props.theme['$font-size-22']};
            color: ${props => props.theme.$green};
            text-transform: uppercase;
            a {
                color:  ${props => props.theme.$gray};
                text-decoration:none;
                &:hover {
                    color:  ${props => props.theme.$gray};
                    text-decoration:none;
                    opacity:0.6;
                }
            }
        }
        p {
            font-size: ${props => props.theme['$font-size-16']};
            margin:0 0 5px 0;
            a {
                color:  ${props => props.theme.$gray};
                text-decoration:none;
                &:hover {
                    color:  ${props => props.theme.$gray};
                    text-decoration:underline;
                }
            }

        }
    }
    &:before {
        position:absolute;
        bottom:0;
        right:0;
        content:'';
        width: 56px;
        height: 36px;
        background: ${props => props.theme['$green-light']}; 

    }
    &:after {
        bottom:8px;
        border-left: 28px solid transparent;
        border-right: 28px solid transparent;
        border-top: 28px solid ${props => props.theme.$green}; 
        border-bottom: 0;                   
    }
`;

export default withTheme(DashboardContactCard);
