import styled, { withTheme } from 'styled-components';
import bp from '@bootstrap-styled/css-mixins/lib/breakpoints';

const Header = styled.div`
    padding:0 0 15px 0;
    ${props => bp.down('md', props.theme['$grid-breakpoints'], `
        padding-bottom:0;
    `)}
    border-bottom: thin solid ${props => props.theme['$hr-color']};
    .container {
        position: relative;

    }
    .navbar-brand {
        margin:0 0 0 15px;
        img {
            ${props => bp.down('md', props.theme['$grid-breakpoints'], `
                width:141px;
            `)}
        }
    }
    .profile-widget {
        position:absolute;
        right:15px;
        top:10px;
        ${props => bp.down('md', props.theme['$grid-breakpoints'], `
            display:none;
        `)}
    }
    .navbar-toggler {
        ${props => bp.up('lg', props.theme['$grid-breakpoints'], `
            display:none;
        `)}
        outline:0;
        background: transparent;
        position:absolute;
        top:0px;
        right:15px;
        margin:0;
        width:50px;
        height:50px;
        border:0;
        border-radius:0px;
        span {
            background-color: ${props => props.theme.$green};
            position:absolute;
            opacity:1;
            border-radius:3px;
            width:40px;
            height:6px;
            transition: .3s ease-in-out all;
            &:nth-child(1) {
                top:20px;
                left:10px;
            }
            &:nth-child(2) {
                top:32px;
                transform: rotate(0deg);
                left:10px;
            }
            &:nth-child(3) {
                top:32px;
                transform: rotate(0deg);
                left:10px;
            }
            &:nth-child(4) {
                top:44px;
                left:10px;
            }
        }
        &.opening {
                    
            span {
                &:nth-child(1) {
                    width:0px;
                    left:50%;
                }
                &:nth-child(2) {
                    left:10px;
                }
                &:nth-child(3) {
                    left:10px;
                }
                &:nth-child(4) {
                    width:0px;
                    left:50%;
                }
            }
        }
        &.opened {
            
            span {
                &:nth-child(1) {
                    width:0%;
                    left:50%;
                }
                &:nth-child(2) {
                    left:10px;
                    transform: rotate(45deg);
                }
                &:nth-child(3) {
                    left:10px;
                    transform: rotate(-45deg);
                }
                &:nth-child(4) {
                    width:0%;
                    left:50%;
                }
            }
        }


    }
    & > .navbar {
        .navbar-collapse {
            ${props => bp.up('lg', props.theme['$grid-breakpoints'], `
                margin: auto 0 0 0;
                .navbar-nav {
                    .nav-item {
                        font-size: ${props.theme['$font-size-14']};
                        margin:0 15px 0 0;
                        &:last-child {
                            margin-right:0;
                        }
                        .nav-link {
                            padding:0;
                            text-transform: uppercase;
                            color: ${props.theme.$gray};
                            border-bottom:2px solid transparent;
                            &:hover, &:active, &.active {
                                border-bottom:2px solid ${props.theme.$green};
                            }
                            &:disabled, &.disabled {
                                border-bottom:2px solid transparent!important;
                                color: ${props.theme['$gray-light']};
                                pointer-events: none;
                            }
                        }
                    }
                }
            `)}

            ${props => bp.down('xs', props.theme['$grid-breakpoints'], `
                max-width: ${props.theme['$container-max-widths'].xs};
            `)}
            ${props => bp.only('sm', props.theme['$grid-breakpoints'], `
                max-width: ${props.theme['$container-max-widths'].sm};
            `)}
            ${props => bp.only('md', props.theme['$grid-breakpoints'], `
                max-width: ${props.theme['$container-max-widths'].md};
            `)}


            ${props => bp.down('md', props.theme['$grid-breakpoints'], `
                
                transition: opacity 0.75s ease-in-out;
                margin:0 auto;
                border:0;
                box-shadow: none;
                background:${props.theme.$green};
                opacity:0;
                z-index:-10;
                max-height:auto !important;
                display:none !important;
                overflow:hidden !important;
                &.show {
                    display:block !important;
                    height:0 !important;
                    opacity:1;
                    z-index:1500;
                    left:0;
                    right:0;
                    top:91px;
                    height:auto !important;
                    bottom:0;
                    position:fixed;
                    overflow:scroll !important;
                    -webkit-overflow-scrolling: touch;
                    padding-bottom:50px;
                    .navbar-nav {
                        .nav-item {
                            font-size: ${props.theme['$font-size-16']};
                            border-bottom:1px solid transparent;
                            .nav-link, .nav-link:focus, .nav-link:active {
                                display:block;
                                position:relative;
                                text-transform:uppercase;
                                padding:25px 30px 25px 15px;
                                color: ${props.theme.$white};
                                background: ${props.theme['$green-lighter']};
                                outline:none !important;
                                &:after {
                                    position:absolute;
                                    bottom:0;
                                    right:0;
                                    content:'';
                                    width: 0;
                                    height: 0;
                                    border-bottom: 40px solid ${props.theme['$green-light']};
                                    border-left: 40px solid transparent;
                            
                                }
                                &:hover, &.active {
                                    color: ${props.theme.$white};
                                    background: ${props.theme['$green-lighter']};
                                    opacity:0.6;
                                }
                            }
                        }
                    }
    
                }
            `)}
        }
    }
`;

export default withTheme(Header);
