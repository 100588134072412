import styled, { withTheme } from 'styled-components';

const WysiwygFragment = styled.div`
p {
    font-size: ${props => props.theme['$font-size-16']};
    margin:0 0 15px 0;

}
b, strong {
    font-weight:700;
}
img {
    max-width:100%;
    height:auto;
}
a {
    color:${props => props.theme.$green};
    text-decoration:underline;
    &:hover {
        opacity:0.6;
    }
 }

`;

export default withTheme(WysiwygFragment);
