import styled, { withTheme } from 'styled-components';
import bp from '@bootstrap-styled/css-mixins/lib/breakpoints';

const CookieConsentFragment = styled.div`
    .container {
        ${props => bp.down('md', props.theme['$grid-breakpoints'], `
        padding:0;
    `)}
    }
    background-color: ${props => props.theme.$white};
    border-top:thin solid ${props => props.theme.$green};
    padding:15px;
    position:fixed;
    width:100%;
    bottom:0;
    &.hidden {
        display: none;
    }
    a {
        color: ${props => props.theme.$green};
    }
    p {
        font-size: ${props => props.theme['$font-size-12']};        
    }
`;

export default withTheme(CookieConsentFragment);
