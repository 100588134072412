import React from 'react';
import {
    PageHeaderProfileWidgetFragment,
} from '../../theme/components';

const PageHeaderProfileWidgetLoading = () => (
    <PageHeaderProfileWidgetFragment />
);

export default PageHeaderProfileWidgetLoading;
