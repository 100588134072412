import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import userLogout from '../../actions/user/userLogout';
import store from '../../services/store';
import debug from '../../services/debug';

// import oktaAuthService from '../../services/okta/auth';
import {
    SESSION_INACTIVITY_TIMER_RESET,
    SESSION_INACTIVITY_TIMER_STARTED,
    SESSION_INACTIVITY_TIMEOUT_LIMIT_REACHED,
    SESSION_EXPIRY_LIMIT_REACHED,
} from '../../actions/session/types';


/**
 * AutomaticLogOut - responsible for managing the user's session in terms of inactivity and session expiry
 * @extends React
 */
class AutomaticLogOut extends React.Component {
    // Properties to hold references to the timers
    sessionInactivityTimerID = null;

    sessionExpiryTimerID = null;

    constructor(props) {
        super(props);
        this.sessionInactivityTimerTick = this.sessionInactivityTimerTick.bind(this);
        this.sessionInactivityLimitReached = this.sessionInactivityLimitReached.bind(this);
        this.clearSessionInactivityTimerTick = this.clearSessionInactivityTimerTick.bind(this);
        this.setSessionExpiryTimeout = this.setSessionExpiryTimeout.bind(this);
        this.expireSession = this.expireSession.bind(this);

        this.state = {
            inactivityTimer: 0,
        };
    }

    componentDidMount() {
        store.dispatch({
            type: SESSION_INACTIVITY_TIMER_RESET,
        });

        this.sessionInactivityTimerTick();
    }

    componentDidUpdate(prevProps) {
        const { route, authenticated } = this.props;
        const { authenticated: prevAuthenticated, route: prevRoute } = prevProps;

        if ((prevAuthenticated !== authenticated) || (prevRoute !== route)) {
            store.dispatch({
                type: SESSION_INACTIVITY_TIMER_RESET,
            });
        }
    }

    componentWillUnmount() {
        // Reset the store timer reset
        store.dispatch({
            type: SESSION_INACTIVITY_TIMER_RESET,
        });

        // Clear the intervals
        this.clearSessionInactivityTimerTick();
        this.clearSessionExpiryTimeout();
    }

    // Check wether the session timeout limit (seconds) has been reached
    sessionInactivityLimitReached = () => {
        const { inactivityTimer } = this.state;
        const timeoutLimit = parseInt(process.env.REACT_APP_AUTOMATIC_INACTIVITY_TIMEOUT_SECONDS, 10) || 900;
        if (inactivityTimer % 10 === 0) {
            // eslint-disable-next-line no-console
            console.log('Inactivity timeout check', { inactivityTimer, timeoutLimit });
        }
        return timeoutLimit <= inactivityTimer;
    }

    // Run an interval in order to tick over the session timer and dispatch redux eactions and logout if applicable
    sessionInactivityTimerTick = () => {
        this.sessionInactivityTimerID = setInterval(() => {
            const { sessionInactivityTimerReset, authenticated } = this.props;
            // If the timer has been reset then start it again
            if (sessionInactivityTimerReset) {
                this.setState({
                    inactivityTimer: 0,
                });

                store.dispatch({
                    type: SESSION_INACTIVITY_TIMER_STARTED,
                });

            // If the user is authenticated and the session limit has been reached then logout the user
            } else if (this.sessionInactivityLimitReached() && authenticated) {
                store.dispatch({
                    type: SESSION_INACTIVITY_TIMEOUT_LIMIT_REACHED,
                });

                this.clearSessionInactivityTimerTick();
                this.clearSessionExpiryTimeout();
                const { auth } = this.props;
                auth.logout('/').then(() => {
                    store.dispatch(userLogout());
                }).catch(err => debug('LogOut', err.message));
            // Keep the timer ticking
            } else {
                const { inactivityTimer } = this.state;
                this.setState({
                    inactivityTimer: inactivityTimer + 1,
                });
            }
        }, 1000);
    };

    // Expires the user session by dispatching the right events and using auth to logout
    expireSession = () => {
        const { authenticated, auth } = this.props;
        if (authenticated) {
            store.dispatch({
                type: SESSION_EXPIRY_LIMIT_REACHED,
            });

            this.clearSessionInactivityTimerTick();

            auth.logout().then(() => {
                store.dispatch(userLogout());
            });
        }
    };

    /**
     * Set the timer running on the session expiry
     * @param {Integer} sessionExpiresIn [description]
     */
    setSessionExpiryTimeout = (sessionExpiresIn) => {
        if (sessionExpiresIn > 0) {
            this.sessionExpiryTimerID = setTimeout(() => {
                this.expireSession();
            }, sessionExpiresIn);
        }
    };

    // Clear the session inactivity Timer Interval
    clearSessionInactivityTimerTick = () => {
        if (this.sessionInactivityTimerID) {
            clearInterval(this.sessionInactivityTimerID);
        }
    };

    // Clear the session expiry timeout Interval
    clearSessionExpiryTimeout = () => {
        if (this.sessionExpiryTimerID) {
            clearTimeout(this.sessionExpiryTimerID);
        }
    };


    render() {
        return null;
    }
}

AutomaticLogOut.propTypes = {
    authenticated: PropTypes.bool,
    route: PropTypes.string,
    sessionInactivityTimerReset: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    auth: PropTypes.object,
};

AutomaticLogOut.defaultProps = {
    authenticated: false,
    route: '',
    sessionInactivityTimerReset: false,
    auth: {},
};
const mapStateToProps = state => ({
    authenticated: state.user.get('authenticated'),
    route: state.router.location.pathname,
    sessionInactivityTimerReset: state.session.get('inactivityTimerReset'),
});

export default connect(mapStateToProps, null)(withAuth(AutomaticLogOut));
