import * as Immutable from 'immutable';
import {
    GET_REPORTGROUPS_REQUESTED,
    GET_REPORTGROUPS_SUCCESSFUL,
    GET_REPORTGROUPS_FAILED,
    GET_REPORTGROUPS_ABORTED,
    FILTER_REPORTGROUPS_REQUESTED,
    FILTER_REPORTGROUPS_SUCCESSFUL,
    FILTER_REPORTGROUPS_FAILED,
    SET_REPORTGROUPSEARCH_REQUESTED,
    SET_REPORTGROUPSEARCH_SUCCESSFUL,
    SET_REPORTGROUP_REQUESTED,
    SET_REPORTGROUP_SUCCESSFUL,
    SET_REPORTGROUPSEARCH_CLEAR_REQUESTED,
    SET_REPORTGROUPSEARCH_CLEAR_SUCCESSFUL,
} from '../../actions/report-groups/types';

const schema = {
    error: null,
    //
    settingsearch: false,
    setsearch: false,
    clearingsearch: false,
    clearedsearch: false,
    //
    search: { name: '' },
    //
    gettingreportgroups: false,
    abortedreportgroups: false,
    gotreportgroups: false,
    //
    reportgroups: [],
    filterringreportgroups: false,
    filteredreportgroups: false,
    reportgroupsfiltered: [],
    //
    settingreportgroup: false,
    setreportgroup: false,
    //
    reportgroup: { reportGroupId: 0 },

};

const initialState = Immutable.Map(schema);

const reportGroupsReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_REPORTGROUPS_REQUESTED:
        return state
            .set('gettingreportgroups', true);

    case GET_REPORTGROUPS_SUCCESSFUL:
        return state
            .set('gettingreportgroups', false)
            .set('gotreportgroups', true)
            .set('reportgroups', action.payload);

    case GET_REPORTGROUPS_ABORTED:
        return state
            .set('abortedreportgroups', true);

    case GET_REPORTGROUPS_FAILED:
        return state
            .set('gettingreportgroups', false)
            .set('error', action.payload);

    case SET_REPORTGROUPSEARCH_REQUESTED:
        return state
            .set('settingsearch', true);

    case SET_REPORTGROUPSEARCH_SUCCESSFUL:
        return state
            .set('settingsearch', false)
            .set('setsearch', true)
            .set('search', action.payload);

    case SET_REPORTGROUPSEARCH_CLEAR_REQUESTED:
        return state
            .set('clearingsearch', true);

    case SET_REPORTGROUPSEARCH_CLEAR_SUCCESSFUL:
        return state
            .set('clearingsearch', false)
            .set('clearedsearch', true)
            .set('search', { name: '' });

    case FILTER_REPORTGROUPS_REQUESTED:
        return state
            .set('filterringreportgroups', true);

    case FILTER_REPORTGROUPS_SUCCESSFUL:
        return state
            .set('filterringreportgroups', false)
            .set('filteredreportgroups', true)
            .set('reportgroupsfiltered', action.payload);

    case FILTER_REPORTGROUPS_FAILED:
        return state
            .set('filterringreportgroups', false)
            .set('error', action.payload);


    case SET_REPORTGROUP_REQUESTED:
        return state
            .set('settingreportgroup', true);

    case SET_REPORTGROUP_SUCCESSFUL:
        return state
            .set('settingreportgroup', false)
            .set('setreportgroup', true)
            .set('reportgroup', action.payload);

    default:
        return state;
    }
};

export { initialState };

export default reportGroupsReducer;
