import * as Immutable from 'immutable';
import content from './content';

// Set up the immutable objct from the content file
const contentMap = Immutable.fromJS(content);

/**
 * Get content from the content json file
 * @param search - an array of strings used in Immutable getin function for deep queries
 */
const getContent = (search) => {
    search.unshift(process.env.REACT_APP_LOCALE);

    const result = contentMap.getIn(search);

    return (result !== undefined) ? result : false;
};

export default getContent;
