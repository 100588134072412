import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import {
    Container,
    Row,
    Col,
    Img,
    CardHeader,
    CardBlock,
    H6,
    P,
} from '@bootstrap-styled/v4';
import NotificationBar from '../notification-bar/component';
import OktaSignInWidget from '../okta-signin-widget/component';
import logo from '../../assets/images/logo-white.png';
import getContent from '../../services/content/getContent';
import {
    LoginFragment,
    FormInfoCard,
    LoginCard,
    FlexHorizontalCenter,
} from '../../theme/components';
import debug from '../../services/debug';

class LogIn extends Component {
    state = {
        authenticated: null,
    };

    constructor(props) {
        super(props);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
    }

    componentDidMount() {
        this.checkAuthentication();
    }

    componentDidUpdate = () => {
        this.checkAuthentication();
    }

    onSuccess(res) {
        const { auth } = this.props;
        if (res.status === 'SUCCESS') {
            return auth.redirect({
                sessionToken: res.session.token,
            });
        }
        return false;
    }

    // eslint-disable-next-line class-methods-use-this
    onError(err) {
        debug('error logging in', err);
    }

    async checkAuthentication() {
        const { authenticated: stateAuthenticated } = this.state;
        const { auth } = this.props;
        const authenticated = await auth.isAuthenticated();
        if (authenticated !== stateAuthenticated) {
            this.setState({ authenticated });
        }
    }


    render() {
        const { authenticated } = this.state;
        const {
            automaticTimeoutLimitReached,
            automaticExpiryLimitReached,
            baseUrl,
            issuer,
            redirectUri,
        } = this.props;

        return authenticated
            ? <Redirect to="/dashboards" />
            : (
                <>
                    <LoginFragment>
                        <Container>
                            <Row>
                                <Col xs="12" md={{ size: 8, offset: 3 }} lg={{ size: 6, offset: 5 }}>
                                    <LoginCard>
                                        <CardBlock>
                                            <Row>
                                                <Col xs="12" xl={{ size: 10, offset: 1 }}>
                                                    <FlexHorizontalCenter>
                                                        <Img fluid="true" src={logo} alt={getContent(['title']).toString()} />
                                                        <OktaSignInWidget
                                                            redirectUri={redirectUri}
                                                            issuer={issuer}
                                                            baseUrl={baseUrl}
                                                            onSuccess={this.onSuccess}
                                                            onError={this.onError}

                                                        />
                                                        {automaticTimeoutLimitReached && (
                                                            <FormInfoCard>
                                                                <CardHeader>
                                                                    <H6>{getContent(['log-in', 'automatic-timeout', 'title'])}</H6>
                                                                </CardHeader>
                                                                <CardBlock>
                                                                    <P>{getContent(['log-in', 'automatic-timeout', 'content'])}</P>
                                                                </CardBlock>
                                                            </FormInfoCard>
                                                        )}

                                                        {automaticExpiryLimitReached && !automaticTimeoutLimitReached && (
                                                            <FormInfoCard>
                                                                <CardHeader>
                                                                    <H6>{getContent(['log-in', 'automatic-expiry', 'title'])}</H6>
                                                                </CardHeader>
                                                                <CardBlock>
                                                                    <P>{getContent(['log-in', 'automatic-expiry', 'content'])}</P>
                                                                </CardBlock>
                                                            </FormInfoCard>
                                                        )}
                                                    </FlexHorizontalCenter>
                                                </Col>
                                            </Row>
                                        </CardBlock>
                                    </LoginCard>
                                </Col>
                            </Row>
                        </Container>
                    </LoginFragment>
                    <NotificationBar />
                </>
            );
    }
}

LogIn.defaultProps = {
    auth: {},
    baseUrl: '/',
    issuer: '',
    redirectUri: '',
    automaticTimeoutLimitReached: false,
    automaticExpiryLimitReached: false,
};

LogIn.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    auth: PropTypes.object,
    baseUrl: PropTypes.string,
    issuer: PropTypes.string,
    redirectUri: PropTypes.string,
    automaticTimeoutLimitReached: PropTypes.bool,
    automaticExpiryLimitReached: PropTypes.bool,
};

export default withAuth(LogIn);
