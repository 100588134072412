import * as Immutable from 'immutable';
import {
    GET_COMMENTARY_CATEGORIES_REQUESTED,
    GET_COMMENTARY_CATEGORIES_SUCCESSFUL,
    GET_COMMENTARY_CATEGORIES_ABORTED,
    GET_COMMENTARY_CATEGORIES_FAILED,
    SET_COMMENTARY_CATEGORY_REQUESTED,
    SET_COMMENTARY_CATEGORY_SUCCESSFUL,
    SET_COMMENTARY_CATEGORY_FAILED,
    CLEAR_COMMENTARY_CATEGORY_REQUESTED,
    CLEAR_COMMENTARY_CATEGORY_SUCCESSFUL,
    CLEAR_COMMENTARY_CATEGORY_FAILED,
} from '../../actions/commentary-categories/types';


const schema = {
    error: null,
    //
    gettingcommentarycategories: false,
    gotcommentarycategories: false,
    abortedcommentarycategories: false,
    //
    commentaryCategories: [],
    //
    settingcommentarycategory: false,
    setcommentarycategory: false,
    clearingcommentarycategory: false,
    clearedcommentarycategory: false,
    //
    commentaryCategory: 0,

};

const initialState = Immutable.Map(schema);

const commentaryCategoriesReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_COMMENTARY_CATEGORIES_REQUESTED:
        return state
            .set('gettingcommentarycategories', true);

    case GET_COMMENTARY_CATEGORIES_ABORTED:
        return state
            .set('gettingcommentarycategories', false)
            .set('abortedcommentarycategories', true);

    case GET_COMMENTARY_CATEGORIES_SUCCESSFUL:
        return state
            .set('gettingcommentarycategories', false)
            .set('gotcommentarycategories', true)
            .set('commentaryCategories', action.payload);

    case GET_COMMENTARY_CATEGORIES_FAILED:
        return state
            .set('gettingcommentarycategories', false)
            .set('error', action.payload);

    case SET_COMMENTARY_CATEGORY_REQUESTED:
        return state
            .set('settingcommentarycategory', true);

    case SET_COMMENTARY_CATEGORY_SUCCESSFUL:
        return state
            .set('settingcommentarycategory', false)
            .set('setcommentarycategory', true)
            .set('commentaryCategory', action.payload);

    case SET_COMMENTARY_CATEGORY_FAILED:
        return state
            .set('settingcommentarycategory', false)
            .set('error', action.payload);

    case CLEAR_COMMENTARY_CATEGORY_REQUESTED:
        return state
            .set('clearingcommentarycategory', true);

    case CLEAR_COMMENTARY_CATEGORY_SUCCESSFUL:
        return state
            .set('clearingcommentarycategory', false)
            .set('clearedcommentarycategory', true)
            .set('commentaryCategory', 0);

    case CLEAR_COMMENTARY_CATEGORY_FAILED:
        return state
            .set('clearingcommentarycategory', false)
            .set('error', action.payload);

    default:
        return state;
    }
};

export { initialState };

export default commentaryCategoriesReducer;
