import styled, { withTheme } from 'styled-components';
import bp from '@bootstrap-styled/css-mixins/lib/breakpoints';

const Footer = styled.footer`
    position:relative;
    padding:0 0 15px 0;
    figure {
        display: block !important;
        text-align: center;
        margin:15px 0 15px 0;
        img {
            width: 153px;
        }
    }
    .nav {
        position:absolute;
        right:15px;
        top:25px;
        display:flex;
        flex-direction: row;
        .nav-item {
            margin:0 15px 0 0;
            font-size: ${props => props.theme['$font-size-12']};    
            color: ${props => props.theme.$green};    
            &:after {
                content: '|';
                margin:0 0 0 15px;
            }
            &:last-child {
                margin-right:0;
                &:after {
                    content: '';
                    margin:0;
                }
            }
            .nav-link {
                padding:0;
                display:inline-block;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        ${props => bp.down('md', props.theme['$grid-breakpoints'], `
            position:static;
            display:flex;
            align-items: center;
            flex-direction: column;
            .nav-item {
                margin:0 0 5px 0;    
                &:after {
                    content: '';
                    margin:0;
                }
            }
        `)}

    }

`;

export default withTheme(Footer);
