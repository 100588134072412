/**
 * A helper service for logging Google Analytics
 * @type {Object}
 */

import ReactGA from 'react-ga';
import debug from '../debug';
import { titleCase } from '../utils/strings';
import {
    LOG_IN,
    LOG_OUT,
} from './categories';

const analyticsService = {};

/**
 * Helper method to format the event in Google Analytics
 * @param string - The string to format
 */
analyticsService.format = string => titleCase(string.replace(/-/g, ' '));

/**
 * Method to log a user log in event in Google Analytics
 * @param label String - The event label for Google Analytics
 */
analyticsService.logLogInEvent = (label) => {
    analyticsService.logEvent({
        category: LOG_IN,
        action: 'All user log ins',
        label,
    });
};

/**
 * Method to log a user log out event in Google Analytics
 * @param label String - The event label for Google Analytics
 */
analyticsService.logLogOutEvent = (label) => {
    analyticsService.logEvent({
        category: LOG_OUT,
        action: 'All user log outs',
        label,
    });
};

/**
 * Method to log a general event in Google Analytics
 * @param event Object - The event for Google Analytics with category, action and label properties
 */
analyticsService.logEvent = (event) => {
    ReactGA.event(event);
    debug('analyticsService', event);
};

export default analyticsService;
