import * as Immutable from 'immutable';
import {
    GET_STATUS_REQUESTED,
    GET_STATUS_SUCCESSFUL,
    GET_STATUS_FAILED,
} from '../../actions/portalstatus/types';

const schema = {
    error: null,
    //
    gettingstatus: false,
    gotstatus: false,
    //
    //
    portalstatus: { state: '' },
};

const initialState = Immutable.Map(schema);

const portalstatusReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_STATUS_REQUESTED:
        return state
            .set('error', null)
            .set('gettingstatus', true);

    case GET_STATUS_SUCCESSFUL:
        return state
            .set('gettingstatus', false)
            .set('gotstatus', true)
            .set('portalstatus', action.payload);

    case GET_STATUS_FAILED:
        return state
            .set('gettingstatus', false)
            .set('error', action.payload);

    default:
        return state;
    }
};

export { initialState };

export default portalstatusReducer;
