import styled, { withTheme } from 'styled-components';
import bp from '@bootstrap-styled/css-mixins/lib/breakpoints';


const HiddenLgUp = styled.div`

    ${props => bp.up('lg', props.theme['$grid-breakpoints'], `
        display:none;
    `)}
`;

export default withTheme(HiddenLgUp);
