import React, { Component } from 'react';
import Cookies from 'js-cookie';
import ReactHtmlParser from 'react-html-parser';
import {
    Container,
    Row,
    Col,
    P,
} from '@bootstrap-styled/v4';

import getContent from '../../services/content/getContent';
import {
    CookieConsentFragment,
    PrimaryButton,
} from '../../theme/components';


class CookieConsent extends Component {
    state = {
        visible: false,
    };

    componentDidMount() {
        if (Cookies.get('cookie-consent') === undefined) {
            this.setState({ visible: true });
        }
    }

    accept() {
        Cookies.set('cookie-consent', true, { expires: 36500 });
        this.setState({ visible: false });
    }

    render() {
        const { visible } = this.state;

        if (!visible) {
            return null;
        }

        return (
            <CookieConsentFragment className={visible ? '' : 'hidden'}>
                <Container>
                    <Row>
                        <Col>
                            <P>{ReactHtmlParser(getContent(['cookie-consent', 'message']))}</P>
                            <PrimaryButton className="btn-sm" onClick={() => this.accept()}>{getContent(['cookie-consent', 'button-label'])}</PrimaryButton>
                        </Col>
                    </Row>
                </Container>
            </CookieConsentFragment>
        );
    }
}

export default CookieConsent;
export { Cookies };
