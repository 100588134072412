import gql from 'graphql-tag';
import getContent from '../../content/getContent';
import graphQLCLient from '../client';

const getPortalStatusQuery = async () => {
    const client = graphQLCLient();

    const query = gql`{
        status {
            state,
        }
    }`;

    return new Promise((resolve, reject) => {
        client.query({ query })
            .then((result) => {
                if (!result.data || !Object.prototype.hasOwnProperty.call(result.data, 'status')) {
                    throw new Error(getContent(['errors', 'invalid-graphQL-response']));
                }
                resolve(result.data.status);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export default getPortalStatusQuery;
