import styled, { withTheme } from 'styled-components';
import BaseButton from './base-button';

const PrimaryButton = styled(BaseButton)`
    background-color: ${props => props.theme.$green} !important;
    border-color: ${props => props.theme.$green} !important;
    &:hover {
        background-color: ${props => props.theme['$green-light']} !important;
        border-color: ${props => props.theme['$green-light']} !important;

    }
`;

export default withTheme(PrimaryButton);
