import styled, { withTheme } from 'styled-components';
import bp from '@bootstrap-styled/css-mixins/lib/breakpoints';

const NavTabsGraphFragment = styled.div`
    ${props => bp.up('lg', props.theme['$grid-breakpoints'], `
        border-bottom:1px solid ${props.theme['$hr-color']};
    `)}
    margin:15px 0 15px 0;
    .nav.nav-tabs {
        display:flex;
        flex-direction: row;
        border:0;
        .nav-item {
            margin:0;
            display:block;
            flex-grow: 1;
            margin:0 5px 0 0;
            &:last-child {
                margin-right:0;
            }
            .nav-link {
                border:0;
                opacity:1;
                border-radius:0;
                padding:6px 0;
                display:block;
                text-align:center;
                text-transform:uppercase;
                font-size: ${props => props.theme['$font-size-12']};
                color: ${props => props.theme['$gray-light']};
                position:relative;
                &:after {
                    transition: all .25s ease-in-out;
                    content:'';
                    position:absolute;
                    left:50%;
                    margin-left:-4px;
                    bottom:-8px;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-top: 8px solid transparent;
                }
                &:hover, &:active, &.active, &:focus {
                    background-color: ${props => props.theme['$brand-primary']};
                    color: ${props => props.theme.$white};
                    outline:none !important;

                    &:after {
                        border-top-color: ${props => props.theme['$brand-primary']};
                    }
                }
            }
        }
    }
`;

export default withTheme(NavTabsGraphFragment);
