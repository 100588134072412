import styled, { withTheme } from 'styled-components';
import bp from '@bootstrap-styled/css-mixins/lib/breakpoints';
import LoginBackground from '../../../assets/images/login-background.jpg';

const LoginFragment = styled.div`
    background: url(${LoginBackground}) no-repeat center center;
    height:100vh;
    background-size:cover;
    ${props => bp.down('md', props.theme['$grid-breakpoints'], `
        margin:0 -15px;
    `)}
`;

export default withTheme(LoginFragment);
