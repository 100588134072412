import { connect } from 'react-redux';
// import userActivityAction from '../../actions/user-activity/userActivity';
import getPortalStatusAction from '../../actions/portalstatus/getPortalStatus';
import SfwSecureRoute from './component';

const mapStateToProps = state => ({
    groups: state.user.get('groups'),
    authenticated: state.user.get('authenticated'),
    status: state.user.get('status'),
    route: state.router.location.pathname,
    state: state.user.get('state'),
    portalstatus: state.portalstatus.get('portalstatus'),
    portalstatuserror: state.portalstatus.get('error'),
});

const mapDispatchToProps = dispatch => ({
    handleGetPortalStatus: async () => {
        await dispatch(await getPortalStatusAction());
    },
});


export default connect(mapStateToProps, mapDispatchToProps)(SfwSecureRoute);
