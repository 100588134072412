import * as Immutable from 'immutable';
import {
    GET_DASHBOARD_CONSULTANT_REQUESTED,
    GET_DASHBOARD_CONSULTANT_SUCCESSFUL,
    GET_DASHBOARD_CONSULTANT_FAILED,
} from '../../actions/dashboard-consultant/types';

const schema = {
    error: null,
    //
    gettingdashboardconsultant: false,
    gotdashboardconsultant: false,
    aborteddashboardconsultant: false,
    //
    //
    consultant: [],
};

const initialState = Immutable.Map(schema);

const dashboardConsultantReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_DASHBOARD_CONSULTANT_REQUESTED:
        return state
            .set('error', null)
            .set('gettingdashboardconsultant', true);


    case GET_DASHBOARD_CONSULTANT_SUCCESSFUL:
        return state
            .set('gettingdashboardconsultant', false)
            .set('gotdashboardconsultant', true)
            .set('consultant', action.payload);

    case GET_DASHBOARD_CONSULTANT_FAILED:
        return state
            .set('gettingdashboardconsultant', false)
            .set('error', action.payload);

    default:
        return state;
    }
};

export { initialState };

export default dashboardConsultantReducer;
