import { setContext } from 'apollo-link-context';
import requestHeaders from '../http/requestHeaders';

/**
 * Add authentication headers to the apollo request
 */
const authMiddleware = setContext(async (operation, { headers }) => {
    try {
        const authorization = await requestHeaders.getAuthorizeHeader();
        return {
            headers: { ...headers, ...authorization },
        };
    } catch (error) {
        throw error;
    }
});

export default authMiddleware;
