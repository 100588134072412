import styled, { withTheme } from 'styled-components';
import bp from '@bootstrap-styled/css-mixins/lib/breakpoints';


const MapFragment = styled.div`
    width: 100%;
    height: 860px;
    ${props => bp.down('md', props.theme['$grid-breakpoints'], `
        border-top:3px solid ${props.theme.$green};
        box-shadow: 0 0 10px 0 rgba(200, 200, 200,1);
        height: 560px;
    `)}
`;

export default withTheme(MapFragment);
