/* eslint-disable no-underscore-dangle */
// import 'babel-polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie11';
import 'unfetch/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { fromJS } from 'immutable';

import { ConnectedRouter } from 'connected-react-router';

// Okta security depandancies
import { Security, Auth } from '@okta/okta-react';
import OktaAuth from '@okta/okta-auth-js';
import oktaSecurityService from './services/okta/security';
import { setEnvironmentConfig } from './services/environment/environment-service';

import AppContainer from './components/app-container/container';
import withTracker from './components/with-tracker/component';

import debug from './services/debug';

import store, { history } from './services/store';
import * as serviceWorker from './serviceWorker';

if (process.env.REACT_APP_DEBUG_STORE === 'true') {
    const listenToStore = () => {
        const state = fromJS(store.getState());
        debug('Redux Store Changed', state.toJS());

        /*
        const state = store.getState();
        const stateJS = {
            router: state.router,
            routerHistory: state.routerHistory,
            portalstatus: state.portalstatus.toJS(),
            commentaries: state.commentaries.toJS(),
            commentary: state.commentary.toJS(),
            commentaryCategories: state.commentaryCategories.toJS(),
            reportgroups: state.reportgroups.toJS(),
            dashboards: state.dashboards.toJS(),
            documents: state.documents.toJS(),
            document: state.document.toJS(),
            documentTypes: state.documentTypes.toJS(),
            contactUsProfilePic: state.contactUsProfilePic.toJS(),
            consultant: state.consultant.toJS(),
            useractivity: state.useractivity.toJS(),
            dashboardConsultant: state.dashboardConsultant.toJS(),
            form: state.form,
            session: state.session.toJS(),
            user: state.user.toJS(),
        };
        debug('Redux Store Changed', stateJS);
        */
    };

    store.subscribe(listenToStore);
}

// eslint-disable-next-line no-shadow
const onAuthRequired = ({ history }) => {
    // Redirect to the /log-in page that has a redirectToLogin
    history.push('/login');
};


/**
*
* Build the app
*
*/
fetch(process.env.REACT_APP_ENVIRONMENT_CONFIG_PATH)
    .then(res => res.json())
    .then(async (env) => {
        // Set environment vars
        setEnvironmentConfig(env);

        // Define the Okta config for the security component
        const oktaConfig = oktaSecurityService.getConfig(onAuthRequired);


        // Extend okta-react's Auth method to accept tokenManager
        class SfwAuth extends Auth {
            constructor(config) {
                super(config);

                const { userAgent } = this._oktaAuth;

                this._oktaAuth = new OktaAuth({
                    url: config.issuer.split('/oauth2/')[0],
                    clientId: config.clientId,
                    issuer: config.issuer,
                    redirectUri: config.redirectUri,
                    onAuthRequired: oktaConfig.onAuthRequired,
                    scope: oktaConfig.scope,
                    tokenManager: config.tokenManager,
                });

                this._oktaAuth.userAgent = userAgent;
            }
        }

        const oktaAuth = new SfwAuth({
            history,
            issuer: oktaConfig.issuer,
            redirectUri: oktaConfig.redirectUri,
            clientId: oktaConfig.clientId,
            tokenManager: oktaConfig.tokenManager,
            scope: oktaConfig.scope,
            onAuthRequired: oktaConfig.onAuthRequired,
        });


        ReactDOM.render(
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <Security auth={oktaAuth}>
                        <Route path="/" component={withTracker(AppContainer)} />
                    </Security>
                </ConnectedRouter>
            </Provider>,

            document.getElementById('root'),
        );
    });


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
