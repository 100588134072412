import styled, { withTheme } from 'styled-components';
import bp from '@bootstrap-styled/css-mixins/lib/breakpoints';


const TabsContentFragment = styled.div.attrs(() => (
    {
        className: 'tab-content',
    }
))`
    ${props => bp.up('lg', props.theme['$grid-breakpoints'], `
        min-height:300px;
        padding:30px 0;
    `)}

    background-color: ${props => props.theme.$white};
`;

export default withTheme(TabsContentFragment);
