const fontSizes = {

    '$font-size-base': '1rem', // 16px
    '$font-size-xs': '0.75rem', // 12px
    '$font-size-sm': '0.875rem', // 14px
    '$font-size-md': '1rem', // 16px
    '$lead-font-size': '1.125rem', // 18px
    '$font-size-lg': '1.375rem', // 22px
    '$font-size-xl': '2.1875rem', // 35px

    '$font-size-h1': '2.8125rem', // 45px
    '$font-size-h2': '2.3125rem', // 48px
    '$font-size-h3': '2rem', // 32px
    '$font-size-h4': '1.5625rem', // 25px
    '$font-size-h5': '1rem', // 16px ??
    '$font-size-h6': '0.875rem', // 14px

    '$font-size-12': '0.75rem', // 12px
    '$font-size-14': '0.875rem', // 14px
    '$font-size-16': '1rem', // 16px
    '$font-size-18': '1.125rem', // 18px
    '$font-size-22': '1.375rem', // 22px
    '$font-size-25': '1.5625rem', // 25px
    '$font-size-28': '1.75rem', // 28px
    '$font-size-30': '1.875rem', // 30px
    '$font-size-32': '2rem', // 32px
    '$font-size-37': '2.3125rem', // 37px
    '$font-size-35': '2.1875rem', // 35px
    '$font-size-40': '2.5rem', // 40px
    '$font-size-45': '2.8125rem', // 45px
    '$font-size-48': '3rem', // 48px
    '$font-size-70': '4.375rem', // 70px


};

export default fontSizes;
