import { getEnvironmentVariable } from '../environment/environment-service';

/**
 * A helper service for using the @okta/okta-react Security component
 * @type {Object} Service with methods
 */


const oktaSecurityService = {};

oktaSecurityService.getConfig = onAuthRequired => ({
    issuer: getEnvironmentVariable('REACT_APP_OKTA_AUTH_ISSUER'),
    clientId: getEnvironmentVariable('REACT_APP_OKTA_CLIENT_ID'),
    redirectUri: getEnvironmentVariable('REACT_APP_OKTA_REDIRECT_URI'),
    onAuthRequired,
    tokenManager: {
        storage: 'sessionStorage',
    },
    pkce: true,
    scope: [
        'openid',
        'email',
        'profile',
        'groups',
    ],
});

export default oktaSecurityService;
