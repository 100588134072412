import styled, { css, keyframes, withTheme } from 'styled-components';

const animation = keyframes`
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
`;

const animationRule = css`
  ${animation} 2s infinite linear;
`;

const LoadingShimmer = styled.span`

    display:block;
    animation: ${animationRule};
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
    height:18px;
    &.white {
        background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
        background-size: 1000px 100%;
    }
    &.green {
        background: linear-gradient(to right, #014823 4%, #01401f 25%, #014823 36%);
        background-size: 1000px 100%;
    }

`;

export default withTheme(LoadingShimmer);
