import React from 'react';
import {
    Container,
    Row,
    Col,
    Img,
    Figure,
    Hr,
    Nav,
    NavItem,
    NavLink,
} from '@bootstrap-styled/v4';
import getContent from '../../services/content/getContent';
import logo from '../../assets/images/logo.svg';
import { Footer } from '../../theme/components';

const PageFooter = () => {
    const footerLinks = getContent(['footer-links']);
    if (!footerLinks) {
        return null;
    }
    const footerLinksJs = footerLinks.toJS();
    return (
        <Footer>
            <Container>
                <Row>
                    <Col>
                        <Hr />
                        <Figure>
                            <Img src={logo} alt={getContent(['title']).toString()} />
                        </Figure>
                        <Nav>
                            {footerLinksJs.map(item => (
                                <NavItem key={item.title}>
                                    <NavLink target="_blank" as="a" href={item.url}>{item.title}</NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                    </Col>
                </Row>
            </Container>
        </Footer>
    );
};
export default PageFooter;
