import styled, { withTheme } from 'styled-components';
import { Input } from '@bootstrap-styled/v4';

const Select = styled(Input)`
    width:100%;
    padding:8px 12px;
    border-radius:3px;
    display:block;
    box-shadow:none;
    background-color: ${props => props.theme.$white} !important;
    border:1px solid ${props => props.theme.$green} !important;
    appearance: none;
    background-image: 
        linear-gradient(45deg, transparent 50%, ${props => props.theme.$green} 50%),
        linear-gradient(135deg, ${props => props.theme.$green} 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;
    font-size: ${props => props.theme['$font-size-14']};
    text-transform: uppercase;
`;

export default withTheme(Select);
