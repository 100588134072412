import styled, { withTheme } from 'styled-components';

const PortfolioHoldingsTotalFragment = styled.div`
    background: ${props => props.theme.$green};
    padding:10px 15px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    h2 {
        color: ${props => props.theme.$white};
        font-weight: ${props => props.theme['$font-weight-normal']};
        line-height: ${props => props.theme['$line-height-none']};
        text-transform: uppercase;
        margin:auto 0;
        font-size: ${props => props.theme['$font-size-28']};
        text-align: right;
    }
    h6 {
        color: ${props => props.theme.$white};
        font-weight: ${props => props.theme['$font-weight-normal']};
        line-height: ${props => props.theme['$line-height-none']};
        text-transform: uppercase;
        margin:auto 0;
        font-size: ${props => props.theme['$font-size-14']};
    }
`;

export default withTheme(PortfolioHoldingsTotalFragment);
