import ApolloClient from 'apollo-client-preset';
import { createHttpLink } from 'apollo-link-http';
import { from } from 'apollo-link';
import authMiddleware from './authMiddleware';
import { getEnvironmentVariable } from '../environment/environment-service';

/**
 * Creates a new Apollo Client using any middleware or afterware required
 */
const graphQLClient = () => {
    const httpLink = createHttpLink({
        uri: getEnvironmentVariable('REACT_APP_GRAPHQL_SERVER'),
    });

    return new ApolloClient({
        link: from([
            authMiddleware,
            httpLink,
        ]),
    });
};

export default graphQLClient;
