import styled, { withTheme } from 'styled-components';

const DashboardLoadingFragment = styled.div`
    h1 {
        background-color: ${props => props.theme.$white};
        height:30px;
        margin:15px 0 15px 0;
    }
    padding:15px 0 15px 0;
    opacity:0.6;
`;

export default withTheme(DashboardLoadingFragment);
