import styled, { withTheme } from 'styled-components';

const ReactTableFragment = styled.div`
    .ReactTable {
        &.-striped {
            border:0;
            .rt-table {
                .rt-thead {
                    box-shadow:none;
                    .rt-tr {
                        .rt-th {
                            &:last-child {
                                text-align:right;
                            }
                            text-align:left;
                            font-weight: ${props => props.theme['$font-weight-normal']};
                            text-transform: uppercase;
                            border-top:0;
                            border-right:0;
                            border-left:0;
                            border-bottom:thin solid ${props => props.theme['$hr-color']}
                            padding:10px 40px 10px 10px;
                            transition: opacity 0.25s, color 0.5s, background-color 0.5s, border-color .5s ease-in-out;
                            box-shadow:none;
                            &:hover {
                                &.-cursor-pointer {
                                    background: ${props => props.theme.$green};
                                    color: ${props => props.theme.$white};
    
                                }
                            }
                            &.-sort-desc {
                                position:relative;
                                &:before {
                                    position:absolute;
                                    background:transparent;
                                    top:0;
                                    right:15px;
                                    content: '\f077';
                                    font-family: 'Font Awesome\ 5 Free';
                                    font-weight: 900;
                                    line-height:40px;
                                    width:30px;
                                }
                            }
                            &.-sort-asc {
                                &:before {
                                    position:absolute;
                                    background:transparent;
                                    top:0;
                                    right:15px;
                                    content: '\f078';
                                    font-family: 'Font Awesome\ 5 Free';
                                    font-weight: 900;
                                    line-height:40px;
                                    width:30px;
                                }
                                
                            }
        
                        }
                    }
                }
                .rt-tbody {
                    .rt-tr-group {
                        border:none;
                        .rt-tr {
                            .rt-td {
                                &:last-child {
                                    text-align:right;
                                }
                                text-align:left;
                                font-weight: ${props => props.theme['$font-weight-bold']};
                                padding:10px 40px 10px 10px;
            
                            }
                            &.-odd {
                                background-color: ${props => props.theme.$white};
                            }
                            &.-even {
                                background-color: ${props => props.theme.$contrast};
                            }
                        }

                    }
                }
            }
        }
    } 
`;

export default withTheme(ReactTableFragment);
