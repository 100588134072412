import styled, { withTheme } from 'styled-components';
import { Button } from '@bootstrap-styled/v4';

const LogoutButton = styled(Button)`
    &.btn {
        border: 1px solid ${props => props.theme.$green};
        background-color: transparent;
        color: ${props => props.theme.$green};
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 0;
        text-transform: uppercase;
        &.mobile {
            border: 1px solid ${props => props.theme.$white};
            background-color: ${props => props.theme.$white};
            color: ${props => props.theme.$green};
            display:block;
            width:100%;
            &:hover {
                border: 1px solid ${props => props.theme.$white};
                background-color: ${props => props.theme.$white};
                color: ${props => props.theme.$green};
                opacity:0.6;
                
            }
    
        }
    }
`;

export default withTheme(LogoutButton);
