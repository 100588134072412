export const USER_UPDATED = 'USER_UPDATED';
export const USER_CREATING = 'USER_CREATING';
export const USER_CREATED = 'USER_CREATED';
export const USER_CREATION_ERROR = 'USER_CREATION_ERROR';
export const USER_LOGIN_REQUESTED = 'USER_LOGIN_REQUESTED';
export const USER_SET_SESSIONTOKEN = 'USER_SET_SESSIONTOKEN';
export const USER_LOGIN_RECAPTCHA_SUCCESS = 'USER_LOGIN_RECAPTCHA_SUCCESS';
export const USER_LOGIN_RECAPTCHA_ERROR = 'USER_LOGIN_RECAPTCHA_ERROR';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_OKTA_ERROR = 'USER_OKTA_ERROR';
export const USER_LOGIN_ERROR_RESET = 'USER_LOGIN_ERROR_RESET';
export const USER_LOGOUT_REQUESTED = 'USER_LOGOUT_REQUESTED';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR';
export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const USER_NOT_AUTHENTICATED = 'USER_NOT_AUTHENTICATED';
export const GET_USER_REQUESTED = 'GET_USER_REQUESTED';
export const GET_USER_RESOLVED = 'GET_USER_RESOLVED';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const UPDATE_LAST_LOGIN_REQUESTED = 'UPDATE_LAST_LOGIN_REQUESTED';
export const UPDATE_LAST_LOGIN_RESOLVED = 'UPDATE_LAST_LOGIN_RESOLVED';
export const UPDATE_LAST_LOGIN_ERROR = 'UPDATE_LAST_LOGIN_ERROR';
export const UPDATE_PROFILE_REQUESTED = 'UPDATE_PROFILE_REQUESTED';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';
export const CHANGE_PASSWORD_REQUESTED = 'CHANGE_PASSWORD_REQUESTED';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

