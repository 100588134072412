const mixins = {
    mixinPlaceHolder: content => `
    &::-webkit-input-placeholder {${content}}
    &:-moz-placeholder           {${content}}
    &::-moz-placeholder          {${content}}
    &:-ms-input-placeholder      {${content}}  
`,
};

export default mixins;
