import styled, { withTheme } from 'styled-components';
import { Card } from '@bootstrap-styled/v4';


const LoginCard = styled(Card)`
    &.card {
        background:rgba(10,99,65,0.9);
        border:0;
        border-radius:0;
        height:100vh;
        #okta-sign-in {
            width:100%;
        }
        & > .card-block {
            padding:15px;
            text-align: center;
            img {
                display:inline-block;
                padding:30px 0 30px 0;
            }
            & > h1 {
                text-align: center;
            }
            & > p {
                text-align: center;
            }
            form .form-group {
                padding:0 0 15px 0;
                & > label {
                    display:block;
                    margin:0 0 5px 0;
                    color: ${props => props.theme.$white};
                    text-transform: uppercase;
                }
                & > .form-control {
                    width:100%;
                    padding:10px 12px;
                    border-radius:0;
                    border:0 !important;
                    display:block;
                    box-shadow:none;
                    background-color: ${props => props.theme.$white};
                    border:1px solid ${props => props.theme['$border-color']};
                    font-size: ${props => props.theme['$font-size-16']};
                    ${props => props.theme.mixinPlaceHolder(`
                        color:${props.theme['$gray-muted']};
                        font-style: italic;
                        text-transform: uppercase;
                    `)}
                }
                & > button {
                    margin:5px 0 0 0;
                }
            }
        }
    
    }
`;

export default withTheme(LoginCard);
