import {
    USER_AUTHENTICATED,
} from './types';


/**
 * Set the store as authenticated and update last login
 *
 * @param {*} decodedToken
 */
const userAuthenticated = async decodedToken => async (dispatch) => {
    const action = {
        type: USER_AUTHENTICATED,
        payload: {
            status: 'ACTIVE',
            profile: decodedToken.profile,
            username: decodedToken.sub,
            authenticated: true,
            groups: decodedToken.groups,
        },
    };

    dispatch(action);
};

export default userAuthenticated;
