import Color from '@bootstrap-styled/color';

const brandColors = {
    $green: '#00512F',
    '$green-light': '#94bb61',

    '$red-dark': '#8F1933',
    $tan: '#BBAA8C',
    $blue: '#376E8F',
    $purple: '#AC89B8',
    '$blue-light': '#75C7D2',
    '$gray-light': '#6A747B',
    $dark: '#232021',
};

export { brandColors };

const colors = {

    $green: brandColors.$green,
    '$green-lighter': '#006543',
    '$green-lightest': '#b7da89',
    '$green-dark': '#004E25',
    '$green-light': brandColors['$green-light'],
    $black: '#000000',
    $white: '#ffffff',
    $red: '#ff0000',
    '$red-dark': brandColors['$red-dark'],
    $contrast: '#f9f8f4',
    '$contrast-darker': '#c7c6c3',
    $gray: '#332D29',
    'gray-light': '#8E8E8E',
    '$gray-muted': '#818181',
    '$gray-light': '#979797',
    '$gray-light-alt': '#e0e0e0',
    '$gray-lighter': '#f9f8f4',
    '$hr-color': '#E4E4E4',
    '$border-color': '#E4E4E4',
    '$blue-light': brandColors['$blue-light'],
    $blue: brandColors.$blue,
    $purple: brandColors.$purple,
    '$brand-gray-light': brandColors['$gray-light'],
    $dark: brandColors.$dark,
    $tan: brandColors.$tan,
};

colors['$body-bg'] = colors.$contrast;
colors['$brand-primary'] = colors.$green;
colors['$brand-danger'] = colors.$red;

colors['$link-color'] = colors['$brand-primary'];
colors['$link-decoration'] = 'none';
colors['$link-hover-color'] = Color(colors['$link-color']).lighten(0.40).toString();
colors['$link-hover-decoration'] = 'none';


export default colors;
