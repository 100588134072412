import {
    USER_LOGOUT_REQUESTED,
    USER_LOGOUT_SUCCESS,
} from './types';

import { STORE_RESET } from '../store/types';
import { SESSION_RESET_ID } from '../session/types';
import analyticsService from '../../services/analytics/analytics';

/**
 * Logout the user using okta client library and re-initialise the redux store
 * @return {dispatch}
 */
const userLogout = () => (dispatch) => {
    analyticsService.logLogOutEvent('Success');

    dispatch({
        type: USER_LOGOUT_REQUESTED,
    });

    dispatch({
        type: STORE_RESET,
    });

    dispatch({
        type: SESSION_RESET_ID,
    });

    return dispatch({
        type: USER_LOGOUT_SUCCESS,
    });
};

export default userLogout;
