import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    CardHeader,
    CardBlock,
    P,
    H6,
    Hr,
    NavLink,
} from '@bootstrap-styled/v4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faExclamationTriangle,
    faUndo,
} from '@fortawesome/free-solid-svg-icons';
import {
    ErrorCard,
    TextMuted,
    TextUpper,
} from '../../theme/components';
import PageHeader from '../page-header/container';
import PageFooter from '../page-footer/container';
import getContent from '../../services/content/getContent';

/**
 * PageNotFound component for 404s
 */

const PageNotFound = () => (
    <Fragment>

        <PageHeader />

        <Container>
            <Row>
                <Col>
                    <ErrorCard>
                        <CardHeader>
                            <H6>{getContent(['pagenotfound', 'title'])}</H6>
                        </CardHeader>
                        <CardBlock>
                            <P>
                                <TextMuted>
                                    <FontAwesomeIcon size="2x" icon={faExclamationTriangle} />
                                </TextMuted>
                                &nbsp;
                                {getContent(['pagenotfound', 'intro'])}
                            </P>
                            <Hr />
                            <NavLink as={Link} to="/dashboards">
                                <FontAwesomeIcon icon={faUndo} />
                                &nbsp;
                                <TextUpper>
                                    {getContent(['pagenotfound', 'linktext'])}
                                </TextUpper>
                            </NavLink>
                        </CardBlock>

                    </ErrorCard>
                </Col>
            </Row>
        </Container>

        <PageFooter />

    </Fragment>

);
export default PageNotFound;
