import styled, { withTheme } from 'styled-components';
import bp from '@bootstrap-styled/css-mixins/lib/breakpoints';
import { Button } from '@bootstrap-styled/v4';

const BaseButton = styled(Button).attrs(() => (
    {
        className: 'btn',
    }
))`
    ${props => bp.down('md', props.theme['$grid-breakpoints'], `
        display:block;
        width:100%;
    `)}
    padding: 8px 15px;
    border-radius: 0 !important;
    text-transform: uppercase;
    color: ${props => props.theme.$white};
    text-decoration: none;
    &:disabled {
        cursor: not-allowed! important;
        opacity:0.2;

    }
`;

export default withTheme(BaseButton);
