import React, { Component } from 'react';
import { SecureRoute } from '@okta/okta-react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import store from '../../services/store';
import debug from '../../services/debug';
// import userLogout from '../../actions/user/userLogout';
import Maintenance from '../app-container/maintenance';

import oktaAuthService from '../../services/okta/auth';
import setSessionId from '../../actions/session/setSessionId';
import userAuthenticated from '../../actions/user/userAuthenticated';
import SfwSecureRouteLoading from './component-loading';
import isAdmin from '../../services/isAdmin';
// import isClient from '../../services/isClient';

class SfwSecureRoute extends Component {
    state = {
        accessToken: false,
    }

    async componentDidMount() {
        const { handleGetPortalStatus } = this.props;
        await this.getClaimsFromToken();
        await handleGetPortalStatus();
    }

    async componentDidUpdate(prevProps) {
        await this.getClaimsFromToken();
        const { portalstatus: prevPortalstatus } = prevProps;
        const { handleGetPortalStatus, portalstatus } = this.props;
        if (prevPortalstatus.state !== portalstatus.state) {
            await handleGetPortalStatus();
        }
    }


    getClaimsFromToken = async () => {
        try {
            const { accessToken: accessTokenState } = this.state;
            const accessToken = await oktaAuthService.client.tokenManager.get('accessToken');

            if (accessToken) {
                const decodedToken = oktaAuthService.decodeJWToken(accessToken.accessToken);

                const { status } = this.props;

                if (status !== 'ACTIVE') {
                    store.dispatch(await userAuthenticated(decodedToken));
                    store.dispatch(await setSessionId());
                    if (accessToken !== accessTokenState) {
                        this.setState({
                            accessToken: accessToken.accessToken,
                        });
                    }
                }
            } else if (accessTokenState) {
                this.setState({
                    accessToken: false,
                });
                const { history } = this.props;
                history.push('/login');
                // window.location.reload();
                // window.location.href = `${process.env.REACT_APP_BASE_URL}/login`;
            }
        } catch (error) {
            throw error;
        }
    }

    getRoute() {
        const {
            groups,
            portalstatus,
            portalstatuserror,
        } = this.props;
        debug(portalstatus);

        if (portalstatus.state === 'offline' || portalstatuserror !== null) {
            return <Maintenance />;
        }

        if (this.showDashboard()) {
            if (isAdmin(groups)) {
                return <Redirect to="/report-groups" />;
            }
            return <Redirect to="/dashboards" />;
        }

        const {
            route,
            component: propComponent,
            render: propRender,
            onEnter,
        } = this.props;

        const component = (!this.stateResolved()) ? SfwSecureRouteLoading : propComponent;
        const render = (!this.stateResolved()) ? () => <SfwSecureRouteLoading /> : propRender;

        return <SecureRoute path={route} component={component} render={render} onEnter={onEnter} />;
    }

    stateResolved() {
        const { state } = this.props;
        return state !== null;
    }

    showDashboard() {
        const { accessToken } = this.state;
        const { authenticated, route } = this.props;
        // Check that the general properties are met for this helper
        let valid = accessToken && authenticated;
        // If the general properties are invalid then return false
        if (!valid) return false;


        // Check specific role properties
        valid = route.indexOf('/dashboards') !== 0
            && route.indexOf('/report-groups') !== 0
            && route.indexOf('/documents') !== 0
            && route.indexOf('/contact-us') !== 0
            && route.indexOf('/news-and-views') !== 0
            && route.indexOf('/account') !== 0;

        /*
        if (this.isAdmin()) {
            valid = this.props.route.indexOf('/dashboards') !== 0;
        } else if (this.isClient()) {
            valid = this.props.route.indexOf('/dashboards') !== 0;
        }
        */

        return valid;
    }

    render() {
        return (
            <div>
                {this.getRoute()}
            </div>
        );
    }
}

SfwSecureRoute.propTypes = {
    authenticated: PropTypes.bool,
    state: PropTypes.string,
    onEnter: PropTypes.func,
    route: PropTypes.string,
    status: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    groups: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    component: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object,
    render: PropTypes.func,
    handleGetPortalStatus: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    portalstatus: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    portalstatuserror: PropTypes.object,
};

SfwSecureRoute.defaultProps = {
    onEnter: () => {},
    render: () => {},
    handleGetPortalStatus: () => {},
    history: {},
    authenticated: false,
    state: '',
    route: '',
    groups: '',
    status: '',
    component: {},
    portalstatus: { state: '' },
    portalstatuserror: {},
};

export default withRouter(SfwSecureRoute);
