import * as Immutable from 'immutable';
import {
    GET_COMMENTARIES_REQUESTED,
    GET_COMMENTARIES_SUCCESSFUL,
    GET_COMMENTARIES_COMPLETED,
    GET_COMMENTARIES_FAILED,
    CLEAR_COMMENTARIES_REQUESTED,
    CLEAR_COMMENTARIES_SUCCESSFUL,
    CLEAR_COMMENTARIES_FAILED,
} from '../../actions/commentaries/types';


const schema = {
    error: null,
    //
    gettingcommentaries: false,
    gotcommentaries: false,
    completedcommentaries: false,
    //
    clearingcommentaries: false,
    clearedcommentaries: false,
    //
    commentaries: [],
};

const initialState = Immutable.Map(schema);

const commentariesReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_COMMENTARIES_REQUESTED:
        return state
            .set('gettingcommentaries', true);

    case GET_COMMENTARIES_COMPLETED:
        return state
            .set('gettingcommentaries', false)
            .set('completedcommentaries', true);

    case GET_COMMENTARIES_SUCCESSFUL:
        return state
            .set('gettingcommentaries', false)
            .set('gotcommentaries', true)
            .set('commentaries', action.payload);

    case GET_COMMENTARIES_FAILED:
        return state
            .set('gettingcommentaries', false)
            .set('error', action.payload);

    case CLEAR_COMMENTARIES_REQUESTED:
        return state
            .set('clearingcommentaries', true);

    case CLEAR_COMMENTARIES_SUCCESSFUL:
        return state
            .set('clearingcommentaries', false)
            .set('clearedcommentaries', true)
            .set('commentaries', []);

    case CLEAR_COMMENTARIES_FAILED:
        return state
            .set('clearingcommentaries', false)
            .set('error', action.payload);

    default:
        return state;
    }
};

export { initialState };

export default commentariesReducer;
