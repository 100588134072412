import styled, { withTheme } from 'styled-components';

const DashboardHeadingFragment = styled.div`
    display:flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    height:100%;
    h6 {
        color: ${props => props.theme['$gray-muted']};
        text-transform: uppercase;
        font-weight: ${props => props.theme['$font-weight-normal']};
    }
    & > h2 {
        margin:auto 0 15px 0;
        font-weight: ${props => props.theme['$font-weight-normal']};
        line-height:${props => props.theme['$font-size-h2']};
    }
    & > h3 {
        margin:auto 0 15px 0;
        font-weight: ${props => props.theme['$font-weight-normal']};
        line-height: 38px;
    }
    &.small {
        & > h3 {
            margin:auto 0 15px 0;
            font-weight: ${props => props.theme['$font-weight-normal']};
        }
        & > h4 {
            margin:auto 0 15px 0;
            font-weight: ${props => props.theme['$font-weight-normal']};
            line-height: 31px;
        }
        
    }
`;

export default withTheme(DashboardHeadingFragment);
