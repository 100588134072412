import * as Immutable from 'immutable';

import {
    GET_COMMENTARY_REQUESTED,
    GET_COMMENTARY_SUCCESSFUL,
    GET_COMMENTARY_FAILED,
    GET_COMMENTARY_CLEARED_REQUESTED,
    GET_COMMENTARY_CLEARED_SUCCESSFUL,
    GET_COMMENTARY_CLEARED_FAILED,
} from '../../actions/commentary/types';


const schema = {
    error: null,
    //
    gettingcommentary: false,
    gotcommentary: false,
    //
    clearingcommentary: false,
    clearedcommentary: false,
    //
    commentary: [],
};

const initialState = Immutable.Map(schema);

const commentaryReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_COMMENTARY_REQUESTED:
        return state
            .set('gettingcommentary', true);

    case GET_COMMENTARY_SUCCESSFUL:
        return state
            .set('gettingcommentary', false)
            .set('gotcommentary', true)
            .set('commentary', action.payload);

    case GET_COMMENTARY_FAILED:
        return state
            .set('gettingcommentary', false)
            .set('error', action.payload);

    case GET_COMMENTARY_CLEARED_REQUESTED:
        return state
            .set('clearingcommentary', true);

    case GET_COMMENTARY_CLEARED_SUCCESSFUL:
        return state
            .set('clearingcommentary', false)
            .set('clearedcommentary', true)
            .set('commentary', {});

    case GET_COMMENTARY_CLEARED_FAILED:
        return state
            .set('clearingcommentary', false)
            .set('error', action.payload);

    default:
        return state;
    }
};

export { initialState };

export default commentaryReducer;
