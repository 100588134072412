import * as Immutable from 'immutable';
import {
    USER_ACTIVITY_UPDATE_REQUESTED,
    USER_ACTIVITY_UPDATE_COMPLETED,
    USER_ACTIVITY_UPDATE_FAILED,
} from '../../actions/user-activity/types';

const schema = {
    error: null,
    //
    settinguseractivity: false,
    setuseractivity: false,
    //
    useractivity: [],
};

const initialState = Immutable.Map(schema);

const userActivityReducer = (state = initialState, action) => {
    switch (action.type) {
    case USER_ACTIVITY_UPDATE_REQUESTED:
        return state
            .set('settinguseractivity', true);

    case USER_ACTIVITY_UPDATE_COMPLETED:
        return state
            .set('settinguseractivity', false)
            .set('setuseractivity', true)
            .set('useractivity', state.get('useractivity').concat(action.payload));

    case USER_ACTIVITY_UPDATE_FAILED:
        return state
            .set('settinguseractivity', false)
            .set('setuseractivity', false);

    default:
        return state;
    }
};

export { initialState };

export default userActivityReducer;
