import * as Immutable from 'immutable';
import {
    GET_DOCUMENT_TYPES_REQUESTED,
    GET_DOCUMENT_TYPES_SUCCESSFUL,
    GET_DOCUMENT_TYPES_ABORTED,
    GET_DOCUMENT_TYPES_FAILED,
    GET_DOCUMENT_TYPES_CLEAR_REQUESTED,
    GET_DOCUMENT_TYPES_CLEAR_SUCCESSFUL,
} from '../../actions/document-types/types';

const schema = {
    error: null,
    //
    gettingdocumenttypes: false,
    gotdocumenttypes: false,
    aborteddocumenttypes: false,
    //
    clearingdocumenttypes: false,
    cleareddocumenttypes: false,
    //
    documentTypes: [],
};

const initialState = Immutable.Map(schema);

const documentTypesReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_DOCUMENT_TYPES_REQUESTED:
        return state
            .set('gettingdocumenttypes', true);

    case GET_DOCUMENT_TYPES_ABORTED:
        return state
            .set('gettingdocumenttypes', true)
            .set('aborteddocumenttypes', true);

    case GET_DOCUMENT_TYPES_SUCCESSFUL:
        return state
            .set('gettingdocumenttypes', false)
            .set('gotdocumenttypes', true)
            .set('documentTypes', action.payload);

    case GET_DOCUMENT_TYPES_FAILED:
        return state
            .set('gettingdocumenttypes', false)
            .set('error', action.payload);

    case GET_DOCUMENT_TYPES_CLEAR_REQUESTED:
        return state
            .set('clearingdocumenttypes', true);

    case GET_DOCUMENT_TYPES_CLEAR_SUCCESSFUL:
        return state
            .set('clearingdocumenttypes', false)
            .set('cleareddocumenttypes', true)
            .set('documentTypes', []);

    default:
        return state;
    }
};

export { initialState };

export default documentTypesReducer;
