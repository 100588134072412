const lineHeights = {
    '$line-height-zero': '0',
    '$line-height-none': '1',
    '$line-height-xs': '1.1',
    '$line-height-sm': '1.2',
    '$line-height-md': '1.3',
    '$line-height-lg': '1.4',
};


export default lineHeights;
