import styled, { withTheme } from 'styled-components';
import BaseDataCard from './base-data-card';

const DashboardListCard = styled(BaseDataCard)`
    & > .card-block {
        padding: 30px 30px 20px 30px;
        h3 {
            font-size: ${props => props.theme['$font-size-40']};
        }
        h4 {
            font-size: ${props => props.theme['$font-size-30']};
        }
        h6 {
            color: ${props => props.theme['$gray-muted']};
            font-size: ${props => props.theme['$font-size-14']};
            font-weight: ${props => props.theme['$font-weight-normal']};
            text-transform: uppercase;
        }
    }
`;

export default withTheme(DashboardListCard);
