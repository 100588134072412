import styled, { withTheme } from 'styled-components';

const CommentaryImage = styled.div`
    border-bottom:5px solid ${props => props.theme['$brand-gray-light']};
    img {
        width:100%;
        height:auto;
    }
`;

export default withTheme(CommentaryImage);
