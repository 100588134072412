import { createStore, combineReducers, applyMiddleware } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';
import { createBrowserHistory } from 'history';

// Import reducers
import reportGroupsReducer from '../reducers/report-groups/reducer';
import dashboardsReducer from '../reducers/dashboards/reducer';
import documentTypesReducer from '../reducers/document-types/reducer';
import documentsReducer from '../reducers/documents/reducer';
import documentReducer from '../reducers/document/reducer';
import userReducer from '../reducers/user/reducer';
import sessionReducer from '../reducers/session/reducer';
import routerHistoryReducer from '../reducers/router-history/reducer';
import commentariesReducer from '../reducers/commentaries/reducer';
import commentaryCategoriesReducer from '../reducers/commentary-categories/reducer';
import commentaryReducer from '../reducers/commentary/reducer';
import contactUsProfilePicReducer from '../reducers/contact-us-profile-pic/reducer';
import consultantReducer from '../reducers/consultant/reducer';
import dashboardConsultantReducer from '../reducers/dashboard-consultant/reducer';
import portalstatusReducer from '../reducers/portalstatus/reducer';
import userActivityReducer from '../reducers/user-activity/reducer';

// Get middleware for history
const history = createBrowserHistory();

// Build middleware
const middleware = applyMiddleware(
    routerMiddleware(history),
    thunk,
);

// Combine the reducers
const reducers = {
    portalstatus: portalstatusReducer,
    reportgroups: reportGroupsReducer,
    dashboards: dashboardsReducer,
    documents: documentsReducer,
    document: documentReducer,
    commentaries: commentariesReducer,
    commentary: commentaryReducer,
    commentaryCategories: commentaryCategoriesReducer,
    contactUsProfilePic: contactUsProfilePicReducer,
    consultant: consultantReducer,
    dashboardConsultant: dashboardConsultantReducer,
    documentTypes: documentTypesReducer,
    useractivity: userActivityReducer,
    session: sessionReducer,
    form: formReducer,
    user: userReducer,
    router: connectRouter(history),
    routerHistory: routerHistoryReducer,
};

const enhancer = composeWithDevTools(
    middleware,
);


// Make our store with our reducers and middleware
const store = createStore(
    connectRouter(history)(combineReducers(reducers)),
    enhancer,
);

export { history };

export default store;
