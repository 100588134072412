import colors from './colors';
import fonts from './fonts';
import fontSizes from './font-sizes';
import fontWeights from './font-weights';
import lineHeights from './line-heights';
import mixins from './mixins';

const variables = {
    ...colors,
    ...fonts,
    ...fontSizes,
    ...fontWeights,
    ...lineHeights,
    ...mixins,
};

export default variables;
