import * as Immutable from 'immutable';
import debug from '../../services/debug';

import {
    USER_LOGIN_REQUESTED,
    USER_SET_SESSIONTOKEN,
    USER_LOGIN_ERROR,
    USER_OKTA_ERROR,
    USER_LOGIN_ERROR_RESET,
    USER_AUTHENTICATED,
    USER_NOT_AUTHENTICATED,
    GET_USER_REQUESTED,
    GET_USER_RESOLVED,
    UPDATE_LAST_LOGIN_REQUESTED,
    UPDATE_LAST_LOGIN_RESOLVED,
    UPDATE_LAST_LOGIN_ERROR,
    UPDATE_PROFILE_REQUESTED,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_ERROR,
    CHANGE_PASSWORD_REQUESTED,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
} from '../../actions/user/types';

import { STORE_RESET } from '../../actions/store/types';

/**
*
* Set the initial state of our app
*
*/
const schema = {
    status: 'INIT',
    loading: false,
    profile: null,
    username: null,
    groups: [],
    authenticated: false,
    authentication: {},
    state: null,
    role: null,
    updatingprofile: false,
    updatedprofile: false,
    recaptchaed: false,
    changingpassword: false,
    changedpassword: false,
    changedpasswordresult: null,
    loginErrorCount: 0,

};

const initialState = Immutable.Map(schema);

/**
*
* The User Reducer
*
* A reducer to manage the state of the user in the store
*
*/
const userReducer = (state = initialState, action) => {
    if (action.type === GET_USER_RESOLVED) debug(action.type);
    if (action.type === USER_AUTHENTICATED) debug(action.type);

    switch (action.type) {
    case STORE_RESET:

        return initialState;

    case GET_USER_REQUESTED:

        return state
            .set('loading', true);

    case GET_USER_RESOLVED:
        return state
            .set('status', 'ACTIVE')
            .set('loading', false)
            .set('username', action.payload.username)
            .set('state', action.payload.state)
            .set('groups', action.payload.groups);

    case USER_LOGIN_REQUESTED:
        return state
            .set('loading', true);

    case USER_SET_SESSIONTOKEN:
        return state
            .set('authentication', action.payload.authentication);

    case USER_LOGIN_ERROR:
        return state
            .set('status', USER_LOGIN_ERROR)
            .set('authentication', action.payload.authentication)
            .set('loginErrorCount', state.get('loginErrorCount') + 1)
            .set('loading', false);

    case USER_OKTA_ERROR:
        return state
            .set('status', USER_OKTA_ERROR)
            .set('authentication', action.payload.authentication)
            .set('loginErrorCount', state.get('loginErrorCount') + 1)
            .set('loading', false);

    case USER_LOGIN_ERROR_RESET:
        return state
            .set('loginErrorCount', 0)
            .set('status', 'INIT');

    case USER_AUTHENTICATED:
        return state
            .set('status', action.payload.status)
            .set('username', action.payload.username)
            .set('authenticated', action.payload.authenticated)
            .set('profile', action.payload.profile)
            .set('state', action.payload.state)
            .set('status', action.payload.status)
            .set('groups', action.payload.groups)
            .set('role', action.payload.role);

    case USER_NOT_AUTHENTICATED:
        return initialState;

    case UPDATE_LAST_LOGIN_REQUESTED:
        return state
            .set('state', Object.assign({}, state.get('state'), {
                lastLogin: 'LOADING',
            }));

    case UPDATE_LAST_LOGIN_RESOLVED:
        return state
            .set('state', Object.assign({}, state.get('state'), action.payload));

    case UPDATE_LAST_LOGIN_ERROR:
        return state
            .set('state', Object.assign({}, state.get('state'), {
                lastLogin: 'ERROR',
            }));

    case UPDATE_PROFILE_REQUESTED:
        return state
            .set('updatingprofile', true);

    case UPDATE_PROFILE_SUCCESS:
        return state
            .set('profile', Object.assign({}, state.get('profile'), action.payload))
            .set('updatingprofile', false)
            .set('updatedprofile', true);

    case UPDATE_PROFILE_ERROR:
        return state
            .set('updatingprofile', false)
            .set('error', action.payload);

    case CHANGE_PASSWORD_REQUESTED:
        return state
            .set('changingpassword', true);

    case CHANGE_PASSWORD_SUCCESS:
        return state
            .set('changedpasswordresult', action.payload)
            .set('changingpassword', false)
            .set('changedpassword', true);

    case CHANGE_PASSWORD_ERROR:
        return state
            .set('changingpassword', false)
            .set('error', action.payload);

    default:
        return state;
    }
};

export { initialState };

export default userReducer;
