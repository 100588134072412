import styled, { withTheme } from 'styled-components';
import BaseDataCard from './base-data-card';

const FormInfoCard = styled(BaseDataCard)`
    box-shadow:none;
    background-color: ${props => props.theme.$contrast};
    & > .card-header {
        background: ${props => props.theme['$green-light']};
    }
    & > .card-block {
        padding:15px;
    }
    &:after {
        border-bottom-color: ${props => props.theme['$green-light']};
    }
`;

export default withTheme(FormInfoCard);
