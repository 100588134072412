import styled, { withTheme } from 'styled-components';
// import styled, { css, keyframes, withTheme } from 'styled-components';
/*
const animation = keyframes`
    0%, 20% {
        color: rgba(0,0,0,0);
        text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);
    }
    40% {
        color: ${props => props.theme.$gray};
        text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);
    }
    60% {
        text-shadow: .25em 0 0 ${props => props.theme.$gray}, .5em 0 0 rgba(0,0,0,0);
    }
    80%, 100% {
        text-shadow: .25em 0 0 ${props => props.theme.$gray}, .5em 0 0 ${props => props.theme.$gray};
    }
`;

const animationRule = css`
  ${animation} 1s steps(5, end) infinite;
`;

const LoadingDots = styled.span`
    &:after {
        content: ' .';
        animation: ${animationRule};
    }
`;
*/

const LoadingDots = styled.span`
    &:after {
        content: ' .';
        animation: dots 1s steps(5, end) infinite;
    }
  
    @keyframes dots {
        0%, 20% {
            color: rgba(0,0,0,0);
            text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);
        }
        40% {
            color: ${props => props.theme.$gray};
            text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);
        }
        60% {
            text-shadow: .25em 0 0 ${props => props.theme.$gray}, .5em 0 0 rgba(0,0,0,0);
        }
        80%, 100% {
            text-shadow: .25em 0 0 ${props => props.theme.$gray}, .5em 0 0 ${props => props.theme.$gray};
        }
    }

`;

export default withTheme(LoadingDots);
