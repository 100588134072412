import styled, { withTheme } from 'styled-components';
import { CardColumns } from '@bootstrap-styled/v4';
import bp from '@bootstrap-styled/css-mixins/lib/breakpoints';


const DashboardCardColumns = styled(CardColumns).attrs(() => (
    {
        className: 'card-columns',
    }
))`
    &.card-columns {
        column-count: 2;
        ${props => bp.down('md', props.theme['$grid-breakpoints'], `
            column-count: 1;
        `)}
    }

`;

export default withTheme(DashboardCardColumns);
