import { createGlobalStyle } from 'styled-components';
import variables from './variables';

const GlobalStyle = createGlobalStyle`
    @font-face {
    font-family: 'perpetuaregular';
    src: url('/fonts/perpetua-webfont.eot');
    src: url('/fonts/perpetua-webfont.eot?#iefix') format('embedded-opentype'),
        url('/fonts/perpetua-webfont.woff2') format('woff2'),
        url('/fonts/perpetua-webfont.woff') format('woff'),
        url('/fonts/perpetua-webfont.ttf') format('truetype'),
        url('/fonts/perpetua-webfont.svg#perpetuaregular') format('svg');
    font-weight: normal;
    font-style: normal;

    }

    @font-face {
        font-family: 'Font Awesome 5 Free';
        src: url('/fonts/fontawesome/fa-solid-900.eot');
        src: url('/fonts/fontawesome/fa-solid-900.eot?#iefix') format('embedded-opentype'),
            url('/fonts/fontawesome/fa-solid-900.woff2') format('woff2'),
            url('/fonts/fontawesome/fa-solid-900.woff') format('woff'),
            url('/fonts/fontawesome/fa-solid-900.ttf') format('truetype'),
            url('/fonts/fontawesome/fa-solid-900.svg#Font Awesome 5 Free') format('svg');
        font-weight: normal;
        font-style: normal;
    
        }

        html {
        overflow-y: scroll; // avoids layout moving when scrollbar isn't needed
    }

    *, ::after, ::before {
        box-sizing: border-box;
    }

    body {
        padding: 0;
        margin: 0;
        font-family: ${variables['$font-family-sans-serif']};
        background-color: ${variables.$contrast};
        letter-spacing: 0.1rem;
        overflow-x:hidden;
    }
    body.prevent-scroll {
        position: fixed;
        width: 100%;
        height: 100%;
        overflow-x: visible;
    }
  
    hr {
        border-top: thin solid ${variables['$hr-color']} !important;
    }

    .serif {
        font-family: ${variables['$font-family-serif']} !important;
    }

    a, button {
        transition: opacity 0.25s, color 0.5s, background-color 0.5s, border-color .5s ease-in-out;
    }

    a, a:vistied, a:active, a:focus {
        color: ${variables['link-color']};
    }

    .clickable {
        cursor:pointer;
    }

    *:focus {
        border:0;
        outline:thin dotted ${variables.$contrast} !important;
        box-shadow: none !important;
    }

    #okta-sign-in {
        margin:0 0 0 0 !important;
    }

    .sfwtoast {
        background: ${variables.$green} !important;
        color:  ${variables.$white} !important;
    }
    .sfwtoast .Toastify__close-button {
        color:  ${variables.$white} !important;

    }
    .sfwtoast .Toastify__progress-bar--default {
        background: linear-gradient(${variables.$green}, ${variables.$white});

    }

    .rt-expandable {
        cursor: unset !important;
      }
      
      .show-pointer
      {
        cursor: pointer !important;
      }
      
`;

export default GlobalStyle;
