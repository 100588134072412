import React from 'react';
import PropTypes from 'prop-types';
import {
    P,
} from '@bootstrap-styled/v4';
import {
    MobileLogoutFragment,
    BrandIcon,
} from '../../theme/components';
import LogOut from '../log-out/component';
import getContent from '../../services/content/getContent';

const MobileLogout = ({ profile }) => {
    if (!profile) return null;

    return (

        <MobileLogoutFragment>
            <P>
                <BrandIcon className="profile-white" />
                &nbsp;
                {profile.title ? `${profile.title} ` : ''}
                {`${profile.firstName}`.length > 0 ? `${profile.firstName} ` : ''}
                {`${profile.lastName}`.length > 0 ? `${profile.lastName} ` : ''}
            </P>
            <LogOut className="mobile">{getContent(['log-out', 'button-label'])}</LogOut>
        </MobileLogoutFragment>

    );
};

MobileLogout.propTypes = {
    profile: PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ])),
};

MobileLogout.defaultProps = {
    profile: {},
};

export default MobileLogout;
