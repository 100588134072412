import * as Immutable from 'immutable';
import {
    GET_DOCUMENTS_REQUESTED,
    GET_DOCUMENTS_SUCCESSFUL,
    GET_DOCUMENTS_ABORTED,
    GET_DOCUMENTS_FAILED,
    GET_DOCUMENTS_CLEAR_REQUESTED,
    GET_DOCUMENTS_CLEAR_SUCCESSFUL,
} from '../../actions/documents/types';

const schema = {
    error: null,
    //
    gettingdocuments: false,
    gotdocuments: false,
    aborteddocuments: false,
    //
    clearingdocuments: false,
    cleareddocuments: false,
    //
    documents: [],
};

const initialState = Immutable.Map(schema);

const documentsReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_DOCUMENTS_REQUESTED:
        return state
            .set('gettingdocuments', true);

    case GET_DOCUMENTS_ABORTED:
        return state
            .set('gettingdocuments', false)
            .set('aborteddocuments', true);

    case GET_DOCUMENTS_SUCCESSFUL:
        return state
            .set('gettingdocuments', false)
            .set('gotdocuments', true)
            .set('documents', action.payload);

    case GET_DOCUMENTS_FAILED:
        return state
            .set('gettingdocuments', false)
            .set('error', action.payload);

    case GET_DOCUMENTS_CLEAR_REQUESTED:
        return state
            .set('clearingdocuments', true);

    case GET_DOCUMENTS_CLEAR_SUCCESSFUL:
        return state
            .set('clearingdocuments', false)
            .set('cleareddocuments', true)
            .set('documents', []);

    default:
        return state;
    }
};

export { initialState };

export default documentsReducer;
