import styled, { withTheme } from 'styled-components';
import { Table } from '@bootstrap-styled/v4';

const StripedTable = styled(Table).attrs(() => (
    {
        className: 'table-striped',
    }
))`
    &.table {
        thead {
            tr {
                th {
                    font-weight: ${props => props.theme['$font-weight-normal']};
                    text-transform: uppercase;
                    border-top:0;
                    border-bottom:thin solid ${props => props.theme['$hr-color']}
                    padding:10px;
                    &.right {
                        text-align: right;
                    }
                }
        
            }
        }
        tbody {
            tr {
                &.selected {
                    th, td {
                        background-color: ${props => props.theme['$green-light']};
                    }
                }
                th, td {
                    font-weight: ${props => props.theme['$font-weight-bold']};
                    padding:10px;
                    &.right {
                        text-align: right;
                    }
                }
            }
            tr:nth-of-type(odd) {
                background-color: ${props => props.theme.$white};
            }
            tr:nth-of-type(even) {
                background-color: ${props => props.theme.$contrast};
            }
        }
    
    }
`;

export default withTheme(StripedTable);
