import React from 'react';
import {
    H6,
    NavLink,
} from '@bootstrap-styled/v4';
import PropTypes from 'prop-types';
import { NavLink as RouterNavLink } from 'react-router-dom';

import LogOut from '../log-out/component';
import getContent from '../../services/content/getContent';
import {
    PageHeaderProfileWidgetFragment,
    BrandIcon,
} from '../../theme/components';
// import getContent from '../../services/content/getContent';
import isAdmin from '../../services/isAdmin';


import PageHeaderProfileWidgetLoading from './component-loading';

const PageHeaderProfileWidget = ({ profile, groups, reportgroup }) => {
    if (!profile) return <PageHeaderProfileWidgetLoading />;

    return (
        <PageHeaderProfileWidgetFragment>
            <H6>
                <NavLink hover="false" activeClassName="active" as={RouterNavLink} to="/account">
                    <BrandIcon className="profile" />
                    &nbsp;
                    {isAdmin(groups) && reportgroup.reportGroupId > 0 ? (
                        <span>{`${reportgroup.reportGroupName}`}</span>
                    ) : (
                        <span>
                            {profile.title ? `${profile.title} ` : ''}
                            {`${profile.firstName}`.length > 0 ? `${profile.firstName} ` : ''}
                            {`${profile.lastName}`.length > 0 ? `${profile.lastName} ` : ''}
                        </span>
                    )}

                </NavLink>
            </H6>
            <LogOut className="btn btn-sm">{getContent(['log-out', 'button-label'])}</LogOut>
        </PageHeaderProfileWidgetFragment>
    );
};

PageHeaderProfileWidget.propTypes = {
    profile: PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ])),
    groups: PropTypes.arrayOf(PropTypes.string),
    // eslint-disable-next-line react/forbid-prop-types
    reportgroup: PropTypes.object,
};

PageHeaderProfileWidget.defaultProps = {
    profile: {},
    groups: [],
    reportgroup: { reportGroupId: 0 },
};

export default PageHeaderProfileWidget;
