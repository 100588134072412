
import styled, { withTheme } from 'styled-components';

const PrimaryBar = styled.div`
    background-color: ${props => props.theme['$green-light']};
    padding:5px 0;
    margin:0 0 30px 0;
    h6 {
        color: ${props => props.theme.$white};
        margin:0;
        text-transform: uppercase;
    }
`;

export default withTheme(PrimaryBar);
