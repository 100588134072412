export const LOG_IN = 'Log in';
export const GROUP_LOG_IN = 'Group log in';
export const LOG_OUT = 'Log out';
export const ACCOUNT_MANAGEMENT = 'Account management';
export const SECURE_MESSAGES = 'Secure messages';
export const INVITATION = 'Invitation';
export const SECURITY_QUESTIONS = 'Security questions';
export const POLICY_TYPE = 'Policy type';
export const ADVISER_CLIENTS = 'Adviser clients';
export const ADVISER_USERS = 'Adviser users';
export const POLICY_PROJECTION = 'Policy projection';
export const UI_TOGGLE = 'UI Toggle';