import React from 'react';
import {
    Container,
} from '@bootstrap-styled/v4';

// import LoadingDots from 'components/loading-dots/component';

const SfwSecureRouteLoading = () => (

    <Container>

        {
            // <LoadingDots />
        }

    </Container>
);

export default SfwSecureRouteLoading;
