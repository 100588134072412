import styled, { withTheme } from 'styled-components';
import BaseDataCard from './base-data-card';

const AdminSearchCard = styled(BaseDataCard)`
    &.card {
        background:${props => props.theme.$white};
        box-shadow: 0 0 10px 0 rgba(200, 200, 200,1);
        border:0;
        border-radius:0;
        & > .card-block {
            padding:15px;
            form .form-group {
                padding:0 0 15px 0;
                & > label {
                    display:block;
                    margin:0 0 5px 0;
                    color: ${props => props.theme.$gray};
                    text-transform: uppercase;
                }
                & > .form-control {
                    &:disabled {
                        border:1px solid ${props => props.theme['$gray-light-alt']};
                        color: ${props => props.theme['$gray-light']};
                        background-color: ${props => props.theme.$contrast};
                        cursor: not-allowed! important;
                    }
                    width:100%;
                    padding:10px 12px;
                    border-radius:0;
                    display:block;
                    box-shadow:none;
                    background-color: ${props => props.theme.$white};
                    border:1px solid ${props => props.theme.$green};
                    font-size: ${props => props.theme['$font-size-16']};
                    ${props => props.theme.mixinPlaceHolder(`
                        color:${props.theme['$gray-muted']};
                        font-style: italic;
                        text-transform: uppercase;
                    `)}
                }
                & > button {
                    margin:5px 0 0 0;
                    background-color: ${props => props.theme.$green};
                    color: ${props => props.theme.$white};
                    &:hover {
                        background-color: ${props => props.theme.$green};
                        color: ${props => props.theme.$white};
                        opacity:0.6;
            
                    }
                }
                    }
        }
    
    }
`;

export default withTheme(AdminSearchCard);
