import styled, { withTheme } from 'styled-components';
import { Card } from '@bootstrap-styled/v4';

const BaseDataCard = styled(Card)`
    &.clickable {
        cursor:pointer;
        &:hover {
            box-shadow: 0 0 10px 0 rgba(200, 200, 200,1);
            .card-header {
                background: ${props => props.theme['$green-light']};
                h6 {
                    color: ${props => props.theme.$white};
                }
            }
        }
    }
    margin:15px 0;
    background-color: ${props => props.theme.$white};
    position:relative;
    box-shadow: 0 0 10px 0 rgba(240, 240, 240, 1);
    border:0 !important;
    border-radius:0 !important;
    &:after {
        position:absolute;
        bottom:0;
        right:0;
        content:'';
        width: 0;
        height: 0;
        border-bottom: 40px solid ${props => props.theme['$green-light']};
        border-left: 40px solid transparent;
    }
    & > .card-header {
        border:0;
        background: ${props => props.theme.$green};
        padding: 5px 30px 5px 30px;
        border-radius:0 !important;
        h6 {
            color: ${props => props.theme.$white};
            font-weight: ${props => props.theme['$font-weight-normal']};
            line-height: ${props => props.theme['$line-height-none']};
            text-transform: uppercase;
            margin:auto 0;
        }
    }
    & > .card-block {
        padding: 30px 30px 20px 30px;
        min-height:90px;
    }
`;

export default withTheme(BaseDataCard);
