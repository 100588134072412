import * as Immutable from 'immutable';
import {
    GET_DOCUMENT_REQUESTED,
    GET_DOCUMENT_SUCCESSFUL,
    GET_DOCUMENT_ABORTED,
    GET_DOCUMENT_FAILED,

} from '../../actions/document/types';

import {
    GET_PORTFOLIOHOLDINGDOCUMENT_REQUESTED,
    GET_PORTFOLIOHOLDINGDOCUMENT_SUCCESSFUL,
    GET_PORTFOLIOHOLDINGDOCUMENT_ABORTED,
    GET_PORTFOLIOHOLDINGDOCUMENT_FAILED,

} from '../../actions/portfolio-holding-document/types';

const schema = {
    error: null,
    //
    gettingdocument: false,
    gotdocument: false,
    aborteddocument: false,
    //
    gettingportfolioholdingdocument: false,
    gotportfolioholdingdocument: false,
    abortedportfolioholdingdocument: false,
    // document: {},
};

const initialState = Immutable.Map(schema);

const documentReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_DOCUMENT_REQUESTED:
        return state
            .set('error', false)
            .set('gettingdocument', true);

    case GET_DOCUMENT_ABORTED:
        return state
            .set('gettingdocument', true)
            .set('aborteddocument', true);

    case GET_DOCUMENT_SUCCESSFUL:
        return state
            .set('gettingdocument', false)
            .set('gotdocument', true)
            // .set('document', action.payload)
            .set('error', '');

    case GET_DOCUMENT_FAILED:
        return state
            .set('gettingdocument', false)
            // .set('document', '')
            .set('error', action.payload);

    case GET_PORTFOLIOHOLDINGDOCUMENT_REQUESTED:
        return state
            .set('gettingportfolioholdingdocument', true);

    case GET_PORTFOLIOHOLDINGDOCUMENT_ABORTED:
        return state
            .set('gettingportfolioholdingdocument', true)
            .set('abortedportfolioholdingdocument', true);

    case GET_PORTFOLIOHOLDINGDOCUMENT_SUCCESSFUL:
        return state
            .set('gettingportfolioholdingdocument', false)
            .set('gotportfolioholdingdocument', true)
            // .set('document', action.payload)
            .set('error', '');

    case GET_PORTFOLIOHOLDINGDOCUMENT_FAILED:
        return state
            .set('gettingportfolioholdingdocument', false)
            // .set('document', '')
            .set('error', action.payload);

    default:
        return state;
    }
};

export { initialState };

export default documentReducer;
