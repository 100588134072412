import styled, { withTheme } from 'styled-components';
import { Card } from '@bootstrap-styled/v4';


const ContactCard = styled(Card).attrs(() => (
    {
        className: 'card-contact',
    }
))`
    &.card {
        background: ${props => props.theme.$white};
        border:0;
        border-radius:0;
        & > .card-block {
            padding: 30px;
            h2 {
                font-size: ${props => props.theme['$font-size-40']};
                margin: 0;
                small {
                    font-size: ${props => props.theme['$font-size-25']};

                }
            }
            h3 {
                font-size: ${props => props.theme['$font-size-18']};
                color: ${props => props.theme.$green};
                margin: 0 0 30px 0;
                text-transform: uppercase
            }
            h4 {
                color: ${props => props.theme['$gray-muted']};
                font-size: ${props => props.theme['$font-size-16']};
                margin: 0 0 5px 0;
            }
            h5 {
                font-size: ${props => props.theme['$font-size-22']};
                margin: 0 0 5px 0;
                a {
                    color:  ${props => props.theme.$gray};
                    text-decoration:none;
                    &:hover {
                        color:  ${props => props.theme.$gray};
                        text-decoration:none;
                        opacity:0.6;
                    }
                }
            }
            p {
                margin: 0;
                font-size: ${props => props.theme['$font-size-16']};
                font-weight: ${props => props.theme['$font-weight-semi-bold']};
                a {
                    color:  ${props => props.theme.$gray};
                    text-decoration:none;
                    &:hover {
                        color:  ${props => props.theme.$gray};
                        text-decoration:underline;
                    }
                }

            }
        }
    
    }
`;

export default withTheme(ContactCard);
