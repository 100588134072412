import styled, { withTheme } from 'styled-components';
import { Table } from '@bootstrap-styled/v4';

const PlainTable = styled(Table).attrs(() => (
    {
        className: 'table-plain',
    }
))`
    &.table {
        margin:0;
        thead {
            tr {
                th {
                    padding:0px;
                    border:0;
                    &.right {
                        text-align: right;
                    }
                }
        
            }
        }
        tbody {
            tr {
                th, td {
                    padding:0px;
                    border:0;
                    &.right {
                        text-align: right;
                    }
                }
            }
        }
    
    }
`;

export default withTheme(PlainTable);
