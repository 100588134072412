// A simple debugger module which only debugs on environments that are not production
const debug = (msg, object = null) => {

    if (`${process.env.NODE_ENV}` !== 'production') {
        // eslint-disable-next-line no-console
        console.log(msg, object);
        return true;
    }

    return false;
}

export default debug;
