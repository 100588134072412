export const GET_COMMENTARY_CATEGORIES_REQUESTED = 'GET_COMMENTARY_CATEGORIES_REQUESTED';
export const GET_COMMENTARY_CATEGORIES_ABORTED = 'GET_COMMENTARY_CATEGORIES_ABORTED';
export const GET_COMMENTARY_CATEGORIES_SUCCESSFUL = 'GET_COMMENTARY_CATEGORIES_SUCCESSFUL';
export const GET_COMMENTARY_CATEGORIES_FAILED = 'GET_COMMENTARY_CATEGORIES_FAILED';

export const SET_COMMENTARY_CATEGORY_REQUESTED = 'SET_COMMENTARY_CATEGORY_REQUESTED';
export const SET_COMMENTARY_CATEGORY_SUCCESSFUL = 'SET_COMMENTARY_CATEGORY_SUCCESSFUL';
export const SET_COMMENTARY_CATEGORY_FAILED = 'SET_COMMENTARY_CATEGORY_FAILED';

export const CLEAR_COMMENTARY_CATEGORY_REQUESTED = 'CLEAR_COMMENTARY_CATEGORY_REQUESTED';
export const CLEAR_COMMENTARY_CATEGORY_SUCCESSFUL = 'CLEAR_COMMENTARY_CATEGORY_SUCCESSFUL';
export const CLEAR_COMMENTARY_CATEGORY_FAILED = 'CLEAR_COMMENTARY_CATEGORY_FAILED';
