import getPortalStatusQuery from '../../services/graphQL/portalstatus/getPortalStatusQuery';
import {
    GET_STATUS_REQUESTED,
    GET_STATUS_SUCCESSFUL,
    GET_STATUS_FAILED,
} from './types';

const getPortalStatusAction = async () => async (dispatch) => {
    dispatch({
        type: GET_STATUS_REQUESTED,
    });

    try {
        const payload = await getPortalStatusQuery();
        return dispatch({
            type: GET_STATUS_SUCCESSFUL,
            payload,
        });
    } catch (err) {
        return dispatch({
            type: GET_STATUS_FAILED,
            payload: err,
        });
    }
};

export default getPortalStatusAction;
