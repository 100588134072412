import styled, { withTheme } from 'styled-components';
import bp from '@bootstrap-styled/css-mixins/lib/breakpoints';

const MobileLogoutFragment = styled.div`
    ${props => bp.up('lg', props.theme['$grid-breakpoints'], `
        display:none;
    `)}
    padding:30px;
    text-align:center;
    p {
        color: ${props => props.theme.$white};
        font-size: ${props => props.theme['$font-size-20']};
        margin:0 0 15px 0;
    }
`;

export default withTheme(MobileLogoutFragment);
