import styled, { css, keyframes, withTheme } from 'styled-components';
import BaseButton from './base-button';

const animation = keyframes`
    0% {
        transform: scale(1) rotate(0deg);
    }
    100% {
        transform: scale(1) rotate(360deg);
    }
`;

const animationRule = css`
  ${animation} 2s infinite linear;
`;

const SubmitButton = styled(BaseButton)`
    &.btn {
        border:thin solid ${props => props.theme.$white};
        background:transparent;
        color: ${props => props.theme.$white};
        width:100%;
        svg {
            margin:0 0 0 15px;
            animation: ${animationRule};
        }
        &:hover {
            border:thin solid ${props => props.theme.$white};
            background:white;
            color: ${props => props.theme.$green};
        }
    
    }
`;

export default withTheme(SubmitButton);
