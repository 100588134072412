import styled, { withTheme } from 'styled-components';
import bp from '@bootstrap-styled/css-mixins/lib/breakpoints';

const PageHeaderProfileWidgetFragment = styled.div.attrs(() => (
    {
        className: 'profile-widget',
    }
))`
    ${props => bp.down('sm', props.theme['$grid-breakpoints'], `
        display:none;
    `)}

    display:flex;
    flex-direction: row;
    align-items: center;
    h6 {
        font-size: ${props => props.theme['$font-size-16']};
        margin:0;
        font-family: ${props => props.theme['$font-family-serif']};
    }
`;

export default withTheme(PageHeaderProfileWidgetFragment);
