
import styled, { withTheme } from 'styled-components';

const FlexHorizontalCenter = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    img {
        align-self: center;
    }
`;

export default withTheme(FlexHorizontalCenter);
