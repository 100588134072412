import styled, { withTheme } from 'styled-components';
import BaseDataCard from './base-data-card';

const ResponsiveDashboardCard = styled(BaseDataCard)`
    &:after {
        border-bottom: 15px solid ${props => props.theme['$green-light']};
        border-left: 15px solid transparent;
    }
    & > .card-header {
        background: ${props => props.theme['$green-light']};
        padding:5px 10px;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        height:34px;
        h6 {
            line-height: ${props => props.theme['$line-height-lg']};
            font-size: ${props => props.theme['$font-size-14']};
            color: ${props => props.theme.$white} !important;
        }
    }
    & > .card-block {
        padding: 10px;
        min-height:0;
        h6 {
            color: ${props => props.theme['$gray-muted']};
            font-size: ${props => props.theme['$font-size-14']};
            font-weight: ${props => props.theme['$font-weight-normal']};
            text-transform: uppercase;
            margin:0;
        }
        p {
            font-size: ${props => props.theme['$font-size-14']};
            margin:0;

        }
    }
`;

export default withTheme(ResponsiveDashboardCard);
