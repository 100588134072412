/* eslint-disable no-param-reassign */
import jsesc from 'jsesc';
import moment from 'moment';

/**
* escapeDoubleQuotes- escape double quotes (e.g. sending through GraphQL query)
* @param {*} str
*/
export const escapeDoubleQuotes = (str) => {
    str = str.toString().replace(/"/g, '\\"');
    return str;
};

/**
* escapeEscapedChars- escape double quotes (e.g. sending through GraphQL query)
* @param {*} str
*/
export const escapeEscapedChars = (str) => {
    str = str.toString().replace((/\n/gm), '\\n');
    str = str.toString().replace((/\r/gm), '\\r');
    str = str.toString().replace((/\r\n/gm), '\\r\\n');
    str = str.toString().replace((/\t/gm), '\\t');
    return str;
};

/** escapeGraphQLInput- escape a string to be sent to GrappQL
* @param {*} str
*/
export const escapeGraphQLInput = (str) => {
    str = escapeEscapedChars(str);
    str = escapeDoubleQuotes(str);
    return str;
};

/**
* escapeString - escape ready for output (e.g. sending to Alpha)
* @param {*} str
*/
export const escapeString = (str) => {
    str = jsesc(str, {
        quotes: 'double',
    });
    return str;
};

/**
* Dertermines if a string is a valid date
* @param {*} str
*/
export const isDate = (str) => {
    const date = moment(str);
    return date.isValid();
};

/**
* Decode an rcpState claim
*/
export const decodeRcpState = str => JSON.parse(decodeURI(str));

/**
 * Converts a string to title case
 *
 * @param {string} str The string to format
 * @returns {string} The formatted string
 */
export const titleCase = (str) => {
    if (str === undefined || str === null) {
        return '';
    }
    return str.toLowerCase().split(' ').map(word => word.replace(word[0], word[0].toUpperCase()).join(' '));
};

/**
 * Converts a string to sentance case
 *
 * @param {string} str The string to format
 * @returns {string} The formatted string
 */
export const sentenceCase = (str) => {
    if (str === undefined || str === null) {
        return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};


/**
 * Removes all whitespace from a string
 *
 * @param {string} str The string to format
 * @returns {string} The formatted string
 */
export const removeSpaces = (str) => {
    if (str === undefined || str === null) {
        return '';
    }
    return str.replace(/\s/g, '');
};

/**
 * Creates a random guid
 *
 * @param {*} length
 */
// eslint-disable-next-line no-bitwise
export const createGuid = (length = 16) => [...Array(length)].map(() => (~~(Math.random() * 36)).toString(36)).join('');
