import { connect } from 'react-redux';
import PageHeaderProfileWidget from './component';

const mapStateToProps = state => ({
    profile: state.user.get('profile'),
    reportgroup: state.reportgroups.get('reportgroup'),
    groups: state.user.get('groups'),
});

export default connect(mapStateToProps)(PageHeaderProfileWidget);
