import styled, { withTheme } from 'styled-components';
import BaseDataCard from './base-data-card';

const CommentaryListCard = styled(BaseDataCard)`
    height:100%;
    &:hover {
        .card-header {
            background: ${props => props.theme['$brand-gray-light']};
        }
    }
    &:after {
        border-bottom-color: ${props => props.theme['$brand-gray-light']};
    }

    & > .card-block {
        height:100%;
        padding: 30px 30px 0 30px;
        border:0 !important;
        border-radius:0;
        h3 {
            font-size: ${props => props.theme['$font-size-35']};
            line-height: ${props => props.theme['$line-height-xs']};
            margin:0 0 15px 0;
        }
        h4 {
            font-size: ${props => props.theme['$font-size-30']};
        }
    }
    & > .card-footer {
        background-color: ${props => props.theme.$white};
        border:0;
        padding: 0 30px 20px 30px;
        h6 {
            color: ${props => props.theme['$gray-muted']};
            font-size: ${props => props.theme['$font-size-14']};
            font-weight: ${props => props.theme['$font-weight-normal']};
        }
    }
`;

export default withTheme(CommentaryListCard);
