import styled, { withTheme } from 'styled-components';

const TabContentFragment = styled.div.attrs(() => (
    {
        className: 'tab-pane fade',
    }
))`
    visibility: hidden;
    opacity: 0;
    height:0;
    overflow:hidden;
    &.show {
        display:block;
        visibility: visible;
        transition: opacity 0.75s;
        opacity: 1;
        height:auto;

    }
`;

export default withTheme(TabContentFragment);
