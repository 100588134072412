import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = [];
const limit = 10;

/**
 * Holds th state for router history
 *
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns
 */
const routerHistoryReducer = (state = initialState, action) => {

    switch(action.type){
        case LOCATION_CHANGE:
            state.push(action.payload);
            if (state.length > limit) {
                state.shift()
            }
            return state;
        default:
            return state;
    }
}

export { initialState };

export default routerHistoryReducer;