import styled, { withTheme } from 'styled-components';

const PercentageBarWrapperFragment = styled.div`
    height:15px;
    &.tinted {
        background-color: ${props => props.theme.$contrast};
    }
`;

export default withTheme(PercentageBarWrapperFragment);
