export const GET_REPORTGROUPS_REQUESTED = 'GET_REPORTGROUPS_REQUESTED';
export const GET_REPORTGROUPS_SUCCESSFUL = 'GET_REPORTGROUPS_SUCCESSFUL';
export const GET_REPORTGROUPS_ABORTED = 'GET_REPORTGROUPS_ABORTED';
export const GET_REPORTGROUPS_FAILED = 'GET_REPORTGROUPS_FAILED';

export const FILTER_REPORTGROUPS_REQUESTED = 'FILTER_REPORTGROUPS_REQUESTED';
export const FILTER_REPORTGROUPS_SUCCESSFUL = 'FILTER_REPORTGROUPS_SUCCESSFUL';
export const FILTER_REPORTGROUPS_ABORTED = 'FILTER_REPORTGROUPS_ABORTED';
export const FILTER_REPORTGROUPS_FAILED = 'FILTER_REPORTGROUPS_FAILED';

export const SET_REPORTGROUPSEARCH_REQUESTED = 'SET_REPORTGROUPSEARCH_REQUESTED';
export const SET_REPORTGROUPSEARCH_SUCCESSFUL = 'SET_REPORTGROUPSEARCH_SUCCESSFUL';
export const SET_REPORTGROUP_REQUESTED = 'SET_REPORTGROUP_REQUESTED';
export const SET_REPORTGROUP_SUCCESSFUL = 'SET_REPORTGROUP_SUCCESSFUL';
export const SET_REPORTGROUPSEARCH_CLEAR_REQUESTED = 'SET_REPORTGROUPSEARCH_CLEAR_REQUESTED';
export const SET_REPORTGROUPSEARCH_CLEAR_SUCCESSFUL = 'SET_REPORTGROUPSEARCH_CLEAR_SUCCESSFUL';
