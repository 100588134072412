import styled, { withTheme } from 'styled-components';
import BaseDataCard from './base-data-card';

const ErrorCard = styled(BaseDataCard)`
    margin:80px 0;
    & > .card-header {
        h6 {
            text-transform: uppercase;
        }
    }
    & > .card-block {
        h4 {
            text-transform: uppercase;
            span {
                vertical-align:middle;

            }
        }
    }
    &:after {
        border-bottom-color: ${props => props.theme['$brand-warning']};
    }
`;

export default withTheme(ErrorCard);
