import React, { Component } from 'react';
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    NavLink,
    Container,
    Img,
} from '@bootstrap-styled/v4';
import { Link, NavLink as RouterNavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import logo from '../../assets/images/logo.svg';
import getContent from '../../services/content/getContent';
import {
    Header,
} from '../../theme/components';
import PageHeaderProfileWidget from '../page-header-profile-widget/container';
import MobileLogout from '../mobile-logout/container';
import isAdmin from '../../services/isAdmin';
// import isClient from '../../services/isClient';


class PageHeader extends Component {
    state = {
        opening: false,
        opened: false,
    };

    getTogglerClassName() {
        const { opened, opening } = this.state;
        if (opened) {
            return 'opened';
        }
        if (opening) {
            return 'opening';
        }
        return '';
    }

    handleRouteChange() {
        document.body.classList.remove('prevent-scroll');
        this.setState({
            opened: false,
            opening: false,
        });
    }

    routeToHome() {
        const { groups } = this.props;
        if (isAdmin(groups)) {
            return '/report-groups';
        }
        return '/dashboards';
    }

    handleToggle(e) {
        e.preventDefault();
        const { opened } = this.state;
        if (!opened) {
            document.body.classList.add('prevent-scroll');
            this.setState({ opening: true });
            setTimeout(() => this.setState({
                opened: true,
                opening: false,
            }), 333);
        } else {
            document.body.classList.remove('prevent-scroll');
            this.setState({
                opened: false,
                opening: false,
            });
        }
    }

    render() {
        const { opened } = this.state;
        const { groups, documents } = this.props;
        return (
            <Header>
                <Navbar light toggleable="md">
                    <Container>
                        <PageHeaderProfileWidget />
                        <div className="d-flex justify-content-between">
                            <NavbarBrand tag={Link} to={this.routeToHome()}>
                                <Img src={logo} alt={getContent(['title']).toString()} />
                            </NavbarBrand>
                            <NavbarToggler className={this.getTogglerClassName()} onClick={e => this.handleToggle(e)}>
                                <span />
                                <span />
                                <span />
                                <span />
                            </NavbarToggler>
                        </div>
                        <Collapse navbar isOpen={opened}>
                            <Nav navbar className="ml-auto">
                                {isAdmin(groups) && (
                                    <NavItem>
                                        <NavLink
                                            onClick={e => this.handleRouteChange(e)}
                                            activeClassName="active"
                                            as={RouterNavLink}
                                            to="/report-groups"
                                        >
                                            Report Groups
                                        </NavLink>
                                    </NavItem>

                                )}
                                <NavItem>
                                    <NavLink onClick={e => this.handleRouteChange(e)} activeClassName="active" as={RouterNavLink} to="/dashboards">Dashboard</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink disabled={isAdmin(groups) && (!documents || documents.length === 0)} onClick={e => this.handleRouteChange(e)} activeClassName="active" as={RouterNavLink} to="/documents">Documents</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={e => this.handleRouteChange(e)} activeClassName="active" as={RouterNavLink} to="/news-and-views">News & Views</NavLink>
                                </NavItem>
                                <NavItem className="d-lg-none">
                                    <NavLink onClick={e => this.handleRouteChange(e)} activeClassName="active" as={RouterNavLink} to="/account">Personal details</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={e => this.handleRouteChange(e)} activeClassName="active" as={RouterNavLink} to="/contact-us">Contact us</NavLink>
                                </NavItem>
                            </Nav>
                            <MobileLogout />
                        </Collapse>
                    </Container>
                </Navbar>
            </Header>
        );
    }
}

PageHeader.propTypes = {
    groups: PropTypes.arrayOf(PropTypes.string),
};

PageHeader.defaultProps = {
    groups: {},
};

export default PageHeader;
