import styled, { withTheme } from 'styled-components';

const PortfolioFooterFragment = styled.div`
    margin:30px 0 0 0;
    h5 {
        margin:0;
        font-size: ${props => props.theme['$font-size-14']};
    }
    p {
        font-size: ${props => props.theme['$font-size-14']};
        color: ${props => props.theme['$gray-light']};
        font-family: ${props => props.theme['$font-family-serif']};
    }
`;

export default withTheme(PortfolioFooterFragment);
