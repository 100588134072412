import {
    SESSION_GET_ID,
    SESSION_SET_ID,
} from './types';
import oktaAuthService from '../../services/okta/auth';

/**
 * Sets a session id in the store
 */
const sessionSetId = async () => async (dispatch, getState) => {
    let session = getState().session.toJS();

    // If we already have a session id then just return the set action
    if (session.id) {
        return (dispatch({
            type: SESSION_SET_ID,
            payload: session.id,
        }));
    }

    // Otherwise get and set the session
    dispatch({
        type: SESSION_GET_ID,
    });

    try {
        session = await oktaAuthService.getSession();
    } catch (error) {
        throw error;
    }

    return (dispatch({
        type: SESSION_SET_ID,
        payload: session.id,
    }));
};

export default sessionSetId;
