import * as Immutable from 'immutable';
import {
    GET_CONSULTANT_REQUESTED,
    GET_CONSULTANT_SUCCESSFUL,
    GET_CONSULTANT_ABORTED,
    GET_CONSULTANT_FAILED,
} from '../../actions/consultant/types';

const schema = {
    error: null,
    //
    gettingconsultant: false,
    gotconsultant: false,
    abortedconsultant: false,
    //
    //
    consultant: [],
};

const initialState = Immutable.Map(schema);

const consultantReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_CONSULTANT_REQUESTED:
        return state
            .set('error', null)
            .set('gettingconsultant', true);

    case GET_CONSULTANT_ABORTED:
        return state
            .set('error', null)
            .set('gettingconsultant', false)
            .set('abortedconsultant', true);

    case GET_CONSULTANT_SUCCESSFUL:
        return state
            .set('gettingconsultant', false)
            .set('gotconsultant', true)
            .set('consultant', action.payload);

    case GET_CONSULTANT_FAILED:
        return state
            .set('gettingconsultant', false)
            .set('error', action.payload);

    default:
        return state;
    }
};

export { initialState };

export default consultantReducer;
