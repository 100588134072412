import OktaAuth from '@okta/okta-auth-js';
import { setEnvironmentConfig, getEnvironmentVariable } from '../environment/environment-service';
/**
 * A helper service for using the @okta/okta-auth-js package
 * @type {Object}
 */

const oktaAuthService = {};

/**
 * Get the config for putting an OktaAuth client
 * @return {[type]} [description]
 */
oktaAuthService.getConfig = () => ({
    url: getEnvironmentVariable('REACT_APP_OKTA_ORIGIN'),
    clientId: getEnvironmentVariable('REACT_APP_OKTA_CLIENT_ID'),
    redirectUri: `${window.location.origin}/implicit/callback`,
    scopes: ['openid', 'email', 'profile', 'groups'],
    tokenManager: {
        storage: 'sessionStorage',
    },
});

fetch(process.env.REACT_APP_ENVIRONMENT_CONFIG_PATH)
    .then(res => res.json())
    .then(async (env) => {
        // Set environment vars
        setEnvironmentConfig(env);

        /**
         * Provide an OktaAuth client with standard config
         * @return {Object} OktaAuth client
         */
        oktaAuthService.client = new OktaAuth(oktaAuthService.getConfig());
    });

/**
 * Gets the accessToken string if one exists in the Okta Auth tokenManager
 */
oktaAuthService.getAccessToken = async () => {
    const token = await oktaAuthService.client.tokenManager.get('accessToken');

    return (token) ? token.accessToken : null;
};

/**
 * Get the current session for a logged in user form OKTA
 */
oktaAuthService.getSession = async () => {
    try {
        const session = await oktaAuthService.client.session.get();
        return session;
    } catch (error) {
        throw error;
    }
};

/**
 * Check the an Okta session exists
 */
oktaAuthService.sessionExists = async () => {
    try {
        const exists = await oktaAuthService.client.session.exists();
        return exists;
    } catch (error) {
        throw error;
    }
};

/**
 * Renews the okta token
 */
oktaAuthService.refreshAccessToken = async () => oktaAuthService.client.tokenManager.renew('accessToken');


// Simple service for checking if the user's session exists
oktaAuthService.isAuthenticated = () => oktaAuthService.client.session.get().then(() => true).catch(() => false);

/**
 * decodeJWToken - Decode a JWT token
 * @param  {String} token (JWT)
 * @return {Object} JWT token properties
 */
oktaAuthService.decodeJWToken = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};

/**
 * getDecodedAccessToken - Gets a decode access token
 * @return {Object} Decoded JWT access token properties
 */
oktaAuthService.getDecodedAccessToken = async () => {
    const token = await oktaAuthService.getAccessToken();
    if (!token) {
        return null;
    }
    return oktaAuthService.decodeJWToken(token);
};

export default oktaAuthService;
