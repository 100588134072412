import styled, { css, keyframes, withTheme } from 'styled-components';
import BaseDataCard from './base-data-card';

const animation = keyframes`
    0% {
        transform: scale(1) rotate(0deg);
    }
    100% {
        transform: scale(1) rotate(360deg);
    }
`;

const animationRule = css`
  ${animation} 2s infinite linear;
`;

const DocumentListCard = styled(BaseDataCard)`
    height:100%;
    &:after {
        display:none;
    }
    &.downloading {
        & > .card-footer {
            h5 {
                a {
                    svg {
                        opacity:1;
                    }
                    &:after {
                        opacity:0;
                    }
                }
            }
        }
    }
    &.doctype-1 {
        &:hover {
            .card-header {
                background: ${props => props.theme['$blue-light']};
            }
        }
        & > .card-footer {
            h5 {
                a {
                    svg {
                        color: ${props => props.theme['$blue-light']};
                    }
                    &:after {
                        border-top-color: ${props => props.theme['$blue-light']};
                    }
                }
            }
        }
    }
    &.doctype-2 {
        &:hover {
            .card-header {
                background: ${props => props.theme.$blue};
            }
        }
        & > .card-footer {
            h5 {
                a {
                    svg {
                        color: ${props => props.theme.$blue};
                    }
                    &:after {
                        border-top-color: ${props => props.theme.$blue};
                    }
                }
            }
        }
    }
    &.doctype-3 {
        &:hover {
            .card-header {
                background: ${props => props.theme.$purple};
            }
        }
        & > .card-footer {
            h5 {
                a {
                    svg {
                        color: ${props => props.theme.$purple};
                    }
                    &:after {
                        border-top-color: ${props => props.theme.$purple};
                    }
                }
            }
        }
    }
    &.doctype-4 {
        &:hover {
            .card-header {
                background: ${props => props.theme.$tan};
            }
        }
        & > .card-footer {
            h5 {
                a {
                    svg {
                        color: ${props => props.theme.$tan};
                    }
                    &:after {
                        border-top-color: ${props => props.theme.$tan};
                    }
                }
            }
        }
    }
    & > .card-block {
        min-height:0;
        height:100%;
        padding: 30px 30px 0 30px;
        h3 {
            font-size: ${props => props.theme['$font-size-40']};
        }
        h4 {
            font-size: ${props => props.theme['$font-size-16']};
        }
    }
    & > .card-footer {
        padding: 0 30px 20px 30px;
        background: ${props => props.theme.$white};
        border:0;
        h6 {
            color: ${props => props.theme['$gray-muted']};
            font-size: ${props => props.theme['$font-size-12']};
            font-weight: ${props => props.theme['$font-weight-normal']};
            line-height: ${props => props.theme['$font-size-16']};
        }
        h5 {
            color: ${props => props.theme.$green};
            font-size: ${props => props.theme['$font-size-16']};
            line-height: ${props => props.theme['$font-size-16']};
            font-weight: ${props => props.theme['$font-weight-normal']};
            a {
                text-decoration: underline;
                position:relative;
                padding:0 40px 0 0;
                svg {
                    position:absolute;
                    right:4px;
                    top:50%;
                    margin-top:-9px;
                    animation: ${animationRule};
                    opacity:0;
                    transition: opacity 0.25s, color 0.5s, background-color 0.5s, border-color .5s ease-in-out;
                }
                &:after {
                    opacity:1;
                    transition: opacity 0.25s, color 0.5s, background-color 0.5s, border-color .5s ease-in-out;
                    position:absolute;
                    right:0;
                    top:50%;
                    margin-top:-7px;
                    content: '';
                    width: 0;
                    height: 0;
                    border-left: 14px solid transparent;
                    border-right: 14px solid transparent;
                    border-top: 14px solid ${props => props.theme.$green};                    
                }
            }
        }
    }
`;

export default withTheme(DocumentListCard);
