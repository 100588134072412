import * as Immutable from 'immutable';

import {
    GET_CONTACTUSPROFILEPIC_REQUESTED,
    GET_CONTACTUSPROFILEPIC_SUCCESSFUL,
    GET_CONTACTUSPROFILEPIC_FAILED,
} from '../../actions/contact-us-profile-pic/types';


const schema = {
    error: null,
    //
    gettingcontactusprofilepic: false,
    gotcontactusprofilepic: false,
    //
    contactusprofilepic: [],
};

const initialState = Immutable.Map(schema);

const contactUsProfilePicReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_CONTACTUSPROFILEPIC_REQUESTED:
        return state
            .set('gettingcontactusprofilepic', true);

    case GET_CONTACTUSPROFILEPIC_SUCCESSFUL:
        return state
            .set('gettingcontactusprofilepic', false)
            .set('gotcontactusprofilepic', true)
            .set('contactusprofilepic', action.payload);

    case GET_CONTACTUSPROFILEPIC_FAILED:
        return state
            .set('gettingcontactusprofilepic', false)
            .set('error', action.payload);

    default:
        return state;
    }
};

export { initialState };

export default contactUsProfilePicReducer;
