import React from 'react';
import { withAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import debug from '../../services/debug';
import store from '../../services/store';
import userLogout from '../../actions/user/userLogout';
import { LogoutButton } from '../../theme/components';

class LogOut extends React.Component {
    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this);
    }

    logout() {
        try {
            const { auth } = this.props;
            auth.logout().then(() => {
                store.dispatch(userLogout());
            }).catch(err => debug('LogOut', err.message));
        } catch (error) {
            debug('LogOut', error.message);
        }
    }

    render() {
        const { children, className } = this.props;
        return (
            <LogoutButton className={className} onClick={this.logout}>{children}</LogoutButton>
        );
    }
}

LogOut.propTypes = {
    className: PropTypes.string, 
    children: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    auth: PropTypes.object,
};

LogOut.defaultProps = {
    className: '',
    children: 'Log off',
    auth: () => {},
};


export default withAuth(LogOut);
