

import store from '../store';
import oktaAuthService from '../okta/auth';

/**
 * A helper service for getting HTTP headers
 * @type {Object}
 */

const requestHeaders = {};

/**
 * Get the current session for a logged in user form OKTA
 * @param {integer} maxWaitTime - the maximum time in milliseconds it will keep polling the store
 */
requestHeaders.getAuthorizeHeader = async (maxWaitTime = 2000) => {
    let sessionId = store.getState().session.get('id');
    const authenticated = store.getState().user.get('authenticated');
    let bearerToken;
    const timeoutMilliSeconds = 50;
    let count = 0;

    const basicAuthToken = process.env.REACT_APP_BASIC_AUTH_USER !== 'false'
        // eslint-disable-next-line prefer-template
        ? `Basic ${btoa(process.env.REACT_APP_BASIC_AUTH_USER + ':' + process.env.REACT_APP_BASIC_AUTH_PASSWD)}` : false;

    if (authenticated) {
        try {
            // Helper to get the current session id property from the store with a delay
            const getSessionId = async ms => new Promise(resolve => setTimeout(() => resolve(store.getState().session.get('id')), ms));

            while (count < (maxWaitTime / timeoutMilliSeconds) && (!sessionId || sessionId === 'LOADING')) {
                count += 1;
                // eslint-disable-next-line no-await-in-loop
                sessionId = await getSessionId(timeoutMilliSeconds);
            }
        } catch (error) {
            throw error;
        }

        if (!sessionId) {
            return (basicAuthToken) ? { authorization: basicAuthToken } : {};
        }

        const accessToken = await oktaAuthService.getAccessToken();

        bearerToken = `Bearer ${accessToken}.${sessionId}`;

        return {
            authorization: basicAuthToken ? `${basicAuthToken}, ${bearerToken}` : bearerToken,
        };
    }

    return (basicAuthToken) ? { authorization: basicAuthToken } : {};
};

export default requestHeaders;
