import styled, { withTheme } from 'styled-components';
import BaseDataCard from './base-data-card';

const LoadingCard = styled(BaseDataCard)`
    & > .card-header {
        background-color: ${props => props.theme['$contrast-darker']};
    }
    & > .card-block {
    }
    opacity:0.6;
    &:after {
        border-bottom-color: ${props => props.theme['$contrast-darker']};
    }
`;

export default withTheme(LoadingCard);
