import React, { Suspense, lazy } from 'react';
import { ImplicitCallback } from '@okta/okta-react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import BootstrapProvider from '@bootstrap-styled/provider/lib/BootstrapProvider';
import { getEnvironmentVariable } from '../../services/environment/environment-service';

import GlobalStyle from '../../theme/GlobalStyle';
import sfwTheme from '../../theme/sfwTheme';
import LogIn from '../log-in/container';
import SfwSecureRoute from '../sfw-secure-route/container';
import AutomaticLogOut from '../automatic-log-out/component';
import CookieConsent from '../cookie-consent/component';
import PageNotFound from './page-not-found';

const LoggedInRouter = lazy(() => import('../logged-in-router/container'));


const securePathRoots = [
    '/report-groups',
    '/dashboards',
    '/documents',
    '/account',
    '/contact-us',
    '/news-and-views',
];

const securePaths = securePathRoots.concat([
    '/dashboards/:dashboardId',
    '/dashboards/:dashboardId/:portfolioId',
    '/news-and-views/:slug',
]);

const getLoadingComponent = () => <span />;

const AppContainer = () => (
    <BootstrapProvider reset theme={sfwTheme}/* injectGlobal="true" */>
        <GlobalStyle />
        <AutomaticLogOut />
        <Suspense fallback={getLoadingComponent()}>
            <Switch>
                <Route exact path="/" render={() => <Redirect to="/dashboards" />} />
                <Route
                    path="/login"
                    render={() => (
                        <LogIn
                            redirectUri={`${getEnvironmentVariable('REACT_APP_OKTA_REDIRECT_URI')}`}
                            baseUrl={`${getEnvironmentVariable('REACT_APP_OKTA_ORIGIN')}`}
                            issuer={`${getEnvironmentVariable('REACT_APP_OKTA_AUTH_ISSUER')}`}
                        />
                    )}
                />
                <SfwSecureRoute path={securePaths} render={props => <LoggedInRouter {...props} />} />
                <Route path="/implicit/callback" component={ImplicitCallback} />
                <Route path="*" component={PageNotFound} />
            </Switch>
        </Suspense>
        <CookieConsent />
    </BootstrapProvider>
);

AppContainer.propTypes = {
};

AppContainer.defaultProps = {
};


export default AppContainer;
