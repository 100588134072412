import { connectWithLifecycle } from 'react-lifecycle-component';

import LogIn from './component';

const mapStateToProps = state => ({
    status: state.user.get('status'),
    loginErrorCount: state.user.get('loginErrorCount'),
    sessionToken: state.user.get('authentication').token,
    automaticTimeoutLimitReached: state.session.get('automaticTimeoutLimitReached'),
    automaticExpiryLimitReached: state.session.get('automaticExpiryLimitReached'),
});


export default connectWithLifecycle(mapStateToProps, null)(LogIn);
