
import styled, { withTheme } from 'styled-components';

const Circle = styled.div`
    width:178px;
    height:178px;
    line-height:150px;
    text-align:center;
    border-radius:178px;
    background-color: ${props => props.theme['$gray-light-alt']};
    margin:0 0 15px 0;
    img {
        border-radius:178px;
    }
`;

export default withTheme(Circle);
