import styled, { withTheme } from 'styled-components';
import bp from '@bootstrap-styled/css-mixins/lib/breakpoints';


const HiddenMdDown = styled.div`

    ${props => bp.down('md', props.theme['$grid-breakpoints'], `
        display:none;
    `)}
`;

export default withTheme(HiddenMdDown);
