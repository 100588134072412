import * as Immutable from 'immutable';
import {
    GET_DASHBOARDS_REQUESTED,
    GET_DASHBOARDS_SUCCESSFUL,
    GET_DASHBOARDS_FAILED,
} from '../../actions/dashboards/types';

import {
    GET_DASHBOARDS_CLEAR_REQUESTED,
    GET_DASHBOARDS_CLEAR_SUCCESSFUL,
    GET_DASHBOARDS_CLEAR_FAILED,
} from '../../actions/dashboards-clear/types';

import {
    GET_DASHBOARDTOTAL_REQUESTED,
    GET_DASHBOARDTOTAL_SUCCESSFUL,
    GET_DASHBOARDTOTAL_ABORTED,
    GET_DASHBOARDTOTAL_FAILED,
} from '../../actions/dashboard-total/types';

import {
    GET_PORTFOLIOS_REQUESTED,
    GET_PORTFOLIOS_SUCCESSFUL,
    GET_PORTFOLIOS_ABORTED,
    GET_PORTFOLIOS_FAILED,
} from '../../actions/portfolios/types';

import {
    GET_PORTFOLIOHOLDINGS_REQUESTED,
    GET_PORTFOLIOHOLDINGS_SUCCESSFUL,
    GET_PORTFOLIOHOLDINGS_ABORTED,
    GET_PORTFOLIOHOLDINGS_FAILED,
} from '../../actions/portfolio-holdings/types';

import {
    GET_PORTFOLIOMOVEMENT_REQUESTED,
    GET_PORTFOLIOMOVEMENT_SUCCESSFUL,
    GET_PORTFOLIOMOVEMENT_ABORTED,
    GET_PORTFOLIOMOVEMENT_FAILED,
} from '../../actions/portfolio-movement/types';

import {
    GET_PORTFOLIOSECTORS_REQUESTED,
    GET_PORTFOLIOSECTORS_SUCCESSFUL,
    GET_PORTFOLIOSECTORS_ABORTED,
    GET_PORTFOLIOSECTORS_FAILED,
} from '../../actions/portfolio-sectors/types';

import {
    GET_PORTFOLIOGRAPH_REQUESTED,
    GET_PORTFOLIOGRAPH_SUCCESSFUL,
    GET_PORTFOLIOGRAPH_ABORTED,
    GET_PORTFOLIOGRAPH_FAILED,
} from '../../actions/portfolio-graph/types';

import {
    GET_PORTFOLIOPERIODRETURNS_REQUESTED,
    GET_PORTFOLIOPERIODRETURNS_SUCCESSFUL,
    GET_PORTFOLIOPERIODRETURNS_ABORTED,
    GET_PORTFOLIOPERIODRETURNS_FAILED,
} from '../../actions/portfolio-period-returns/types';


const schema = {
    error: null,
    //
    gettingdashboards: false,
    gotdashboards: false,
    //
    clearingdashboards: false,
    cleareddashboards: false,
    //
    gettingdashboardtotal: false,
    gotdashboardtotal: false,
    aborteddashboardtotal: false,
    //
    gettingportfolios: false,
    gotportfolios: false,
    abortedportfolios: false,
    //
    gettingportfolioholdings: false,
    gotportfolioholdings: false,
    abortedportfolioholdings: false,
    //
    gettingportfoliomovement: false,
    gotportfoliomovement: false,
    abortedportfoliomovement: false,
    //
    gettingportfoliosectors: false,
    gotportfoliosectors: false,
    abortedportfoliosectors: false,
    //
    gettingportfoliograph: false,
    gotportfoliograph: false,
    abortedportfoliograph: false,
    //
    gettingportfolioperiodreturns: false,
    gotportfolioperiodreturns: false,
    abortedportfolioperiodreturns: false,
    //
    dashboards: [],
};

const initialState = Immutable.Map(schema);

const dashboardsReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_DASHBOARDS_REQUESTED:
        return state
            .set('gettingdashboards', true);

    case GET_DASHBOARDS_SUCCESSFUL:
        return state
            .set('gettingdashboards', false)
            .set('gotdashboards', true)
            .set('dashboards', action.payload);

    case GET_DASHBOARDS_FAILED:
        return state
            .set('gettingdashboards', false)
            .set('error', action.payload);

    case GET_DASHBOARDS_CLEAR_REQUESTED:
        return state
            .set('clearingdashboards', true);

    case GET_DASHBOARDS_CLEAR_SUCCESSFUL:
        return state
            .set('clearingdashboards', false)
            .set('cleareddashboards', true)
            .set('dashboards', []);

    case GET_DASHBOARDS_CLEAR_FAILED:
        return state
            .set('clearingdashboards', false)
            .set('error', action.payload);

    case GET_DASHBOARDTOTAL_REQUESTED:
        return state
            .set('gettingdashboardtotal', true);

    case GET_DASHBOARDTOTAL_ABORTED:
        return state
            .set('gettingdashboardtotal', true)
            .set('aborteddashboardtotal', true);

    case GET_DASHBOARDTOTAL_SUCCESSFUL:
        return state
            .set('gettingdashboardtotal', false)
            .set('gotdashboardtotal', true)
            .set('dashboards', action.payload);

    case GET_DASHBOARDTOTAL_FAILED:
        return state
            .set('gettingdashboardtotal', false)
            .set('error', action.payload);

    case GET_PORTFOLIOS_REQUESTED:
        return state
            .set('gettingportfolios', true);

    case GET_PORTFOLIOS_SUCCESSFUL:
        return state
            .set('gettingportfolios', false)
            .set('gotportfolios', true)
            .set('dashboards', action.payload);

    case GET_PORTFOLIOS_ABORTED:
        return state
            .set('gettingportfolios', false)
            .set('gotportfolios', true)
            .set('abortedportfolios', true);

    case GET_PORTFOLIOS_FAILED:
        return state
            .set('gettingportfolios', false)
            .set('error', action.payload);

    case GET_PORTFOLIOHOLDINGS_REQUESTED:
        return state
            .set('gettingportfolioholdings', true);

    case GET_PORTFOLIOHOLDINGS_SUCCESSFUL:
        return state
            .set('gettingportfolioholdings', false)
            .set('gotportfolioholdings', true)
            .set('dashboards', action.payload);

    case GET_PORTFOLIOHOLDINGS_ABORTED:
        return state
            .set('gettingportfolioholdings', false)
            .set('gotportfolioholdings', true)
            .set('abortedportfolioholdings', true);

    case GET_PORTFOLIOHOLDINGS_FAILED:
        return state
            .set('gettingportfolioholdings', false)
            .set('error', action.payload);

    case GET_PORTFOLIOMOVEMENT_REQUESTED:
        return state
            .set('gettingportfoliomovement', true);

    case GET_PORTFOLIOMOVEMENT_SUCCESSFUL:
        return state
            .set('gettingportfoliomovement', false)
            .set('gotportfoliomovement', true)
            .set('dashboards', action.payload);

    case GET_PORTFOLIOMOVEMENT_ABORTED:
        return state
            .set('gettingportfoliomovement', false)
            .set('gotportfoliomovement', true)
            .set('abortedportfoliomovement', true);

    case GET_PORTFOLIOMOVEMENT_FAILED:
        return state
            .set('gettingportfoliomovement', false)
            .set('error', action.payload);

    case GET_PORTFOLIOSECTORS_REQUESTED:
        return state
            .set('gettingportfoliosectors', true);

    case GET_PORTFOLIOSECTORS_SUCCESSFUL:
        return state
            .set('gettingportfoliosectors', false)
            .set('gotportfoliosectors', true)
            .set('dashboards', action.payload);

    case GET_PORTFOLIOSECTORS_ABORTED:
        return state
            .set('gettingportfoliosectors', false)
            .set('gotportfoliosectors', true)
            .set('abortedportfoliosectors', true);

    case GET_PORTFOLIOSECTORS_FAILED:
        return state
            .set('gettingportfoliosectors', false)
            .set('error', action.payload);

    case GET_PORTFOLIOGRAPH_REQUESTED:
        return state
            .set('gettingportfoliograph', true);

    case GET_PORTFOLIOGRAPH_SUCCESSFUL:
        return state
            .set('gettingportfoliograph', false)
            .set('gotportfoliograph', true)
            .set('dashboards', action.payload);

    case GET_PORTFOLIOGRAPH_ABORTED:
        return state
            .set('gettingportfoliograph', false)
            .set('gotportfoliograph', true)
            .set('abortedportfoliograph', true);

    case GET_PORTFOLIOGRAPH_FAILED:
        return state
            .set('gettingportfoliograph', false)
            .set('error', action.payload);

    case GET_PORTFOLIOPERIODRETURNS_REQUESTED:
        return state
            .set('gettingportfolioperiodreturns', true);

    case GET_PORTFOLIOPERIODRETURNS_SUCCESSFUL:
        return state
            .set('gettingportfolioperiodreturns', false)
            .set('gotportfolioperiodreturns', true)
            .set('dashboards', action.payload);

    case GET_PORTFOLIOPERIODRETURNS_ABORTED:
        return state
            .set('gettingportfolioperiodreturns', false)
            .set('gotportfolioperiodreturns', true)
            .set('abortedportfolioperiodreturns', true);

    case GET_PORTFOLIOPERIODRETURNS_FAILED:
        return state
            .set('gettingportfoliograph', false)
            .set('error', action.payload);

    default:
        return state;
    }
};

export { initialState };

export default dashboardsReducer;
